import { IrnInput } from "@/components/forms/IrnInput.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { PhoneInput } from "@/components/ui/phone-input.tsx";
import { selectUserInfo } from "@/store/Auth.Slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { UpdateUserInfoRequest, useUpdateUserInfoMutation } from "@/store/IRN.API.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input/min";
import * as yup from "yup";

const validationSchema = yup.object<UpdateUserInfoRequest>().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  phone: yup
    .string()
    .required()
    .test("is-valid-phone-number", "Please enter a valid phone number", (value) => !value || isValidPhoneNumber(value)),
  email: yup.string().email("Please enter a valid email address").required("Please enter your email address"),
});

export function UpdateContactInfoDialog({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const userInfo = useAppSelector(selectUserInfo);
  const [updateTrigger, updateResult] = useUpdateUserInfoMutation();
  const dispatch = useAppDispatch();
  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),

    defaultValues: {
      firstName: userInfo?.firstName ?? "",
      lastName: userInfo?.lastName ?? "",
      phone: userInfo?.phoneNumber ?? "",
      email: userInfo?.email ?? "",
    },
  });

  useEffect(() => {
    if (updateResult.isSuccess) {
      setOpen(false);
      dispatch(showNotification({ message: "Contact info updated", severity: "success" }));
    }
  }, [dispatch, updateResult.isSuccess]);

  // Whenever the current user info changes, update the form default values
  useEffect(() => {
    form.reset({
      firstName: userInfo?.firstName ?? "",
      lastName: userInfo?.lastName ?? "",
      phone: userInfo?.phoneNumber ?? "",
      email: userInfo?.email ?? "",
    });
  }, [userInfo]);

  const onSubmit = (data: UpdateUserInfoRequest) => {
    updateTrigger({
      ...data,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children}
        {/*<Button variant="link">Update</Button>*/}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit contact info</DialogTitle>
          <DialogDescription>Make changes to your contact info here. Click save when you&apos;re done.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} autoComplete="given-name" />
                  </FormControl>
                  {/*<FormDescription>This is your public display name.</FormDescription>*/}
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} autoComplete="family-name" />
                  </FormControl>
                  {/*<FormDescription>This is your public display name.</FormDescription>*/}
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <PhoneInput {...field} defaultCountry="US" placeholder="Enter a phone number" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <IrnInput name="email" control={form.control} label="Email" auto-complete="email" />
            <DialogFooter>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
