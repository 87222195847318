import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useVerifyDistributorOtpMutation } from "@/store/IRN.API.ts";
import { useState } from "react";
import { useParams } from "react-router-dom";

export function DistributorOtpInput() {
  // This page is on /app/otp/<otp_request_id>
  // The otp_request_id is passed in the URL
  // The user will enter the OTP here
  // The OTP will be sent to the server to verify
  // If the OTP is correct, the user will be notified that their distributor import is being processed

  const { otpRequestId } = useParams<{ otpRequestId: string }>();
  const [otp, setOtp] = useState<string>("");

  const [verifyOtpMutation, { data: verifyOtpData, error: verifyOtpError, isLoading: verifyOtpIsLoading }] = useVerifyDistributorOtpMutation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    verifyOtpMutation({
      requestId: otpRequestId || "",
      otp: otp,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="p-8 bg-white rounded-lg shadow-md max-w-2xl w-96">
        <h1 className="mb-6 text-2xl font-bold text-center">Enter One-Time-Password</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter your OTP" />
          <Button disabled={verifyOtpIsLoading} className="w-full">
            Submit OTP
          </Button>
        </form>

        {/* If the OTP is incorrect, show an error message */}
        {verifyOtpError && <p className="text-red-500 text-center mt-4">Invalid OTP. Please try again.</p>}

        {/* If the OTP is correct, show a success message */}
        {verifyOtpData && (
          <div className="mt-4 text-center">
            <p className="text-green-500">Your distributor import is being processed.</p>
          </div>
        )}

        {/* FAQ */}

        <Accordion type="single" collapsible className="mt-5">
          <AccordionItem value="item-1">
            <AccordionTrigger>What is this?</AccordionTrigger>
            <AccordionContent className="flex flex-col space-y-4">
              <p>
                This is a one-time password (OTP) sent by your distributor to help us securely access your account as part of multi-factor authentication. We
                use this step to safely pull the data needed to process your distributor import and keep your account information up to date.
              </p>
              <p>
                If we don’t receive the OTP in time or at all, we won’t be able to complete the import process, which could delay updates to your account. If
                you&apos;re unsure what this is or didn’t request it, feel free to ignore it—no further action is needed.
              </p>
              <p>Need help? We&apos;re here for you! Contact us if you have questions or run into any issues.</p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
