export function DistributorConnectionHelpText() {
  return (
    <>
      <div className="prose mx-auto max-w-4xl py-8">
        <h1 className="text-2xl font-bold text-gray-800">What is a Distributor Connection, and How Does it Work?</h1>
        <p className="text-gray-600">
          A <strong>distributor connection</strong> is a convenient feature that allows IRN to securely log in to your distributor’s web portal on your behalf.
          By doing so, we can automatically download your order guides, pricing, and other order-related information—saving you time and effort! This process
          runs on a set schedule (usually once per day), so your data is always up to date and ready when you need it.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mt-6">How the Distributor Connection Works</h2>
        <ol className="list-decimal list-inside space-y-4 mt-4">
          <li>
            <strong>Automated Logins:</strong>
            <p className="text-gray-600">
              Our system securely logs in to your distributor’s web portal using the credentials you provide. Once logged in, it retrieves the necessary
              information, such as:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Order guides</li>
              <li>Pricing details</li>
              <li>Other order-related data</li>
            </ul>
            <p className="text-gray-600">
              This information is then used to enhance your experience on our platform by streamlining order management and ensuring accurate details.
            </p>
          </li>
          <li>
            <strong>Encryption and Security:</strong>
            <p className="text-gray-600">
              Your username and password are encrypted at all times. Even after you provide your credentials, they remain hidden—even from us at IRN. We only
              use them when it’s time to log in to the distributor’s portal, and only for the purpose of retrieving the order-related data you need.
            </p>
            <p className="text-gray-600">Rest assured, your login details are never shared with third parties or exposed to unauthorized access.</p>
          </li>
          <li>
            <strong>Privacy Assurance:</strong>
            <p className="text-gray-600">
              We value your trust and privacy. During this process, we <strong>do not</strong> collect any personal information (like billing details or private
              correspondence). Our sole focus is retrieving the order-related data that helps streamline your experience.
            </p>
          </li>
        </ol>

        <h2 className="text-xl font-semibold text-gray-800 mt-6">What if My Distributor Uses Two-Factor Authentication (2FA/MFA)?</h2>
        <p className="text-gray-600">
          If your distributor’s portal requires additional verification, like a 2FA or MFA code, we’ve got you covered! Here’s how it works:
        </p>
        <ol className="list-decimal list-inside space-y-4 mt-4">
          <li>
            <strong>MFA Detection:</strong>
            <p className="text-gray-600">When our system tries to log in and detects an MFA requirement, we’ll send you a notification via SMS or email.</p>
          </li>
          <li>
            <strong>Time-Sensitive Code Entry:</strong>
            <p className="text-gray-600">
              In the notification, you’ll find a secure link to the IRN website where you can quickly input the required code. Once entered, our system will
              complete the login process and retrieve your order-related information.
            </p>
          </li>
          <li>
            <strong>Opting in for SMS Notifications:</strong>
            <p className="text-gray-600">
              If you’d like to receive MFA requests via SMS, you’ll need to opt in and agree to the terms outlined below. This ensures we can notify you
              promptly when your input is needed.
            </p>
          </li>
        </ol>

        <h2 className="text-xl font-semibold text-gray-800 mt-6">Frequently Asked Questions</h2>
        <dl className="space-y-4 mt-4">
          <div>
            <dt className="font-medium text-gray-800">Is my password safe with IRN?</dt>
            <dd className="text-gray-600">
              Absolutely! Your password is fully encrypted using industry-standard encryption techniques. It is only used for logging in to your distributor’s
              portal and is never visible to IRN or any third parties.
            </dd>
          </div>
          <div>
            <dt className="font-medium text-gray-800">What kind of data does IRN retrieve from my distributor?</dt>
            <dd className="text-gray-600">
              IRN only collects order-related data, such as order guides and pricing information. Personal, billing, or unrelated data is not accessed or
              stored.
            </dd>
          </div>
          <div>
            <dt className="font-medium text-gray-800">What happens if I miss an MFA code request?</dt>
            <dd className="text-gray-600">
              If you don’t provide the MFA code, the login attempt will fail, and we won’t be able to retrieve your data for that session. Don’t worry—our
              system will try again during the next scheduled login window.
            </dd>
          </div>
          <div>
            <dt className="font-medium text-gray-800">Can I update or revoke my credentials?</dt>
            <dd className="text-gray-600">
              Of course! You can update or remove your distributor credentials at any time through your account settings in the IRN platform.
            </dd>
          </div>
        </dl>

        <p className="text-gray-600 mt-6">
          By setting up a distributor connection, you’re making your ordering process easier and more efficient—giving you more time to focus on what matters
          most. If you have any additional questions or need assistance, don’t hesitate to reach out to our support team!
        </p>
      </div>
    </>
  );
}
