import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { OrderDetails } from "@/components/orders/OrderDetails.tsx";
import { OrderDetailsDto, useGetOrderDetailsByIdQuery } from "@/store/IRN.API.ts";
import { useParams } from "react-router-dom";

export function OrderPage() {
  const { orderId } = useParams();
  const { data, isLoading, error } = useGetOrderDetailsByIdQuery(parseInt(orderId!));

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  return (
    <>
      {(isLoading || !data || !data?.order?.id) && <LoadingSpinner size="xl" />}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {error && <p>Error: {error.message}</p>}
      {!isLoading && !error && data && data?.order?.id && <OrderDetails order={data as OrderDetailsDto} />}
    </>
  );
}
