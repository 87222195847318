import { Log } from "@/Log.ts";
import { useConfirm } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { ControllableUpdateBusinessInfoDialog } from "@/components/dialogs/update-business-info/UpdateBusinessInfoDialog.tsx";
import { OrderDetailsItemizedList } from "@/components/orders/OrderDetailsItemizedList.tsx";
import { OrderDetailsSidebarSummary } from "@/components/orders/OrderDetailsSidebarSummary.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useIsInRole } from "@/hooks";
import { ApplicationRole, DistributorDto, OrderDetailsDto, OrderStatus, useCompleteOrderMutation } from "@/store/IRN.API.ts";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export function OrderDetails({ order }: { order: OrderDetailsDto }) {
  const navigate = useNavigate();
  const [businessInfoDialogOpen, setBusinessInfoDialogOpen] = useState(false);
  const [businessRequiredInfoValid] = useState(false);
  const [, completeOrderResult] = useCompleteOrderMutation();
  const [orderItemsByDistributor, setOrderItemsByDistributor] = useState<{
    [key: string]: {
      id: number;
      quantity: number;
      product: {
        name: string;
        price: number;
        distributor: DistributorDto;
      };
    }[];
  }>({});
  const isDeveloperRole = useIsInRole(ApplicationRole.Developer);

  // Calculate the total owed. This should only include products from distributors who are owned by business ID = 1
  const [totalDueToIrn, setTotalDueToIrn] = useState(0);
  const confirmationDialog = useConfirm();

  useEffect(() => {
    const itemsByDistributor: { [key: string]: { id: number; quantity: number; product: { name: string; price: number; distributor: DistributorDto } }[] } = {};
    order.lineItems?.forEach((item) => {
      if (!itemsByDistributor[item.distributorProduct.distributor!.name]) {
        itemsByDistributor[item.distributorProduct.distributor!.name] = [];
      }
      itemsByDistributor[item.distributorProduct.distributor!.name].push({
        quantity: item.quantity,
        id: item.id,

        product: {
          name: item.distributorProduct.name,
          price: item.distributorProduct.price,
          distributor: item.distributorProduct.distributor!,
        },
      });
    });

    // if (isDeveloperRole) {
    //   // Duplicate each order/distributor 3 times so we can see some more data presented for layout testing
    //   Object.keys(itemsByDistributor).forEach((distributor) => {
    //     itemsByDistributor[distributor + " - #2"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #3"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #4"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #5"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #6"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #7"] = [...itemsByDistributor[distributor]];
    //   });
    // }

    setOrderItemsByDistributor(itemsByDistributor);

    const totalDueToIrn = Object.keys(itemsByDistributor)
      .filter((distributorName) => itemsByDistributor[distributorName][0].product.distributor.ownedByBusinessId === 1)
      .reduce((acc, distributor) => acc + itemsByDistributor[distributor].reduce((acc, item) => acc + item.quantity * item.product.price, 0), 0);

    setTotalDueToIrn(totalDueToIrn);
  }, [isDeveloperRole, order.lineItems]);

  useEffect(() => {
    if (!businessInfoDialogOpen && businessRequiredInfoValid) {
      Log.info("OrderPage", "OrderPage", "useEffect", "businessInfoDialogOpen", businessInfoDialogOpen);
    }
  }, [businessInfoDialogOpen, businessRequiredInfoValid]);

  return (
    <div className="flex gap-10">
      <ControllableUpdateBusinessInfoDialog
        businessId={order.business?.id ?? 0}
        requireFields={true}
        open={businessInfoDialogOpen}
        setOpen={setBusinessInfoDialogOpen}
      />
      <div className="w-full">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">Order Details</h1>

        {order.status === OrderStatus.NotPlaced && (
          <div className="mt-5 rounded border-2 border-brand bg-white p-5 w-full">
            <p className="mt-2 text-sm text-gray-500">This order has not been placed yet. You can complete the order by clicking the button below.</p>
            <p className="mt-2 text-sm text-gray-500">
              Once you place the order, your payment method will be charged and the order will be sent to the distributor(s). You will receive an email
              notification when the order has been received by the distributor(s) with tracking information.
            </p>
            <div className="mt-4">
              <Button
                type="button"
                onClick={() => {
                  if (totalDueToIrn > 0) {
                    confirmationDialog({
                      title: "Complete Order",
                      body: (
                        <div className="leading-loose space-y-5">
                          <p>
                            By clicking &quot;Complete Order&quot; you will be directed to our checkout page to completed the order in the amount of{" "}
                            <span className="text-brand-600 font-semibold">
                              {totalDueToIrn.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                            </span>
                            .
                          </p>
                          <p>
                            You will be charged for the order and the order will be sent to the distributor(s). You will receive an email notification when the
                            order has been received by the distributor(s) with tracking information.
                          </p>
                          <p>
                            Please note that IRN is not responsible for any shipping or payment related to distributor(s) not directly partnered with IRN.
                            Please contact your distributor(s) directly for any questions or concerns regarding this order.
                          </p>
                        </div>
                      ),
                      actionButton: "Complete Order",
                      cancelButton: "Cancel",
                    }).then((result) => {
                      if (result) {
                        navigate("./checkout", { relative: "path" });
                      }
                    });
                  } else {
                    confirmationDialog({
                      title: "Complete Order",
                      body: (
                        <div className="leading-loose space-y-5">
                          <p>
                            There is no amount due to IRN for this order. By clicking &quot;Complete Order&quot;, IRN will automatically place the order with
                            the distributor(s) and you will receive an email notification when the order has been received by the distributor(s).
                          </p>
                          <p>
                            Please note that IRN is not responsible for any shipping or payment related to this order, as these distributors are not partnered
                            with IRN.
                          </p>
                          <p>Please contact your distributor(s) directly for any questions or concerns regarding this order.</p>
                        </div>
                      ),
                      actionButton: "Complete Order",
                      cancelButton: "Cancel",
                    }).then((result) => {
                      if (result) {
                        console.log("TODO: Send order to distributor(s)");
                      }
                    });
                  }
                  // setBusinessInfoDialogOpen(true);
                  // completeOrderTrigger(order.id);
                }}
                disabled={completeOrderResult.isLoading}
              >
                {/*{completeOrder.isLoading && <LoadingSpinner size="xs" color="white" className="mr-2" />}*/}
                Complete Order
              </Button>
            </div>
          </div>
        )}

        <OrderDetailsItemizedList orderItemsByDistributor={orderItemsByDistributor} />
      </div>
      <OrderDetailsSidebarSummary
        order={order}
        orderId={order.orderId}
        createdAt={order.createdAt}
        status={order.status}
        business={order.business!}
        orderItemsByDistributor={orderItemsByDistributor}
      >
        {order.status !== OrderStatus.NotPlaced && (
          <Button asChild>
            <Link to={"./receipt"} relative="path">
              View Invoice
            </Link>
          </Button>
        )}
      </OrderDetailsSidebarSummary>
    </div>
  );
}
