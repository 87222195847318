import {
  OrderGuideTableOnHandCell,
  OrderGuideTableParCell,
  OrderGuideTableQuantityCell,
} from "@/components/dashboard/order-guide/cells/OrderGuideTableQuantityCell.tsx";
import { useConfirm, usePrompt } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { OrderGuideSelectionWidget } from "@/components/order-guide-selection-widget/OrderGuideSelectionWidget.tsx";
import { Button } from "@/components/ui/button.tsx";
import { DataTable } from "@/components/ui/data-table.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import {
  OrderGuideDto,
  OrderGuideItemDto,
  OrderGuideType,
  useChangeOrderGuideTypeMutation,
  useDeleteOrderGuideMutation,
  useRemoveProductFromOrderGuideMutation,
  useUpdateOrderGuideMutation,
} from "@/store/IRN.API.ts";
import { selectEnableParEditing, setEnableParEditing } from "@/store/OrderGuide.slice.ts";
import { currentOrderGuideItemsTotalItems, currentOrderGuideItemsTotalPrice, selectOrderGuideItems } from "@/store/OrderGuideItem.slice.ts";
import { toCurrency } from "@/utils.ts";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { Column, ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { ClipboardEdit, MoreVertical, Pencil, Trash } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formClasses } from "../../forms/fields/FormUtils.tsx";
import { ProductTableItemDetailsCell } from "../products/ProductTableItemDetailsCell.tsx";

export function OrderGuideTable({ guide }: { guide: OrderGuideDto | null }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const data = useAppSelector(selectOrderGuideItems);
  // const [sorting, setSorting] = useState<SortingState>([]);
  const [filter, setFilter] = useState<string>("");

  const estimatedTotal = useAppSelector(currentOrderGuideItemsTotalPrice);
  const itemCount = useAppSelector(currentOrderGuideItemsTotalItems);
  const parEditingEnabled = useAppSelector(selectEnableParEditing);
  const navigate = useNavigate();
  const confirmationDialog = useConfirm();
  const inputDialog = usePrompt();

  const dispatch = useAppDispatch();

  const [deleteOrderGuideTrigger, deleteOrderGuideResult] = useDeleteOrderGuideMutation();
  const [updateOrderGuideTrigger] = useUpdateOrderGuideMutation();
  const [deleteOrderGuideItemTrigger] = useRemoveProductFromOrderGuideMutation();
  const [changeOrderGuideTypeTrigger] = useChangeOrderGuideTypeMutation();

  const sortHeader = (column: Column<OrderGuideItemDto>, text: string) => {
    let sortDescending: boolean | undefined = undefined;
    if (column.getIsSorted() === false) {
      sortDescending = true;
    } else if (column.getIsSorted() === "asc") {
      sortDescending = undefined;
    } else if (column.getIsSorted() === "desc") {
      sortDescending = false;
    }
    return (
      <Button variant="ghost" onClick={() => column.toggleSorting(sortDescending, true)}>
        {text}
        {/*{column.getIsSorted() === "asc" ? (
          <ArrowUpIcon className="ml-2 h-4 w-4" />
        ) : column.getIsSorted() === "desc" ? (
          <ArrowDownIcon className="ml-2 h-4 w-4" />
        ) : (
          <ArrowsUpDownIcon className="ml-2 h-4 w-4" />
        )}*/}
      </Button>
    );
  };

  // const rerender = useReducer(() => ({}), {})[1];
  const columns = useMemo<ColumnDef<OrderGuideItemDto>[]>(
    () => [
      {
        accessorKey: "id",
        header: "#",
        enableSorting: false,
        cell: (item) => {
          return <div>{item.row.index + 1}</div>;
        },
      },
      {
        accessorKey: "productMetadata.name",
        header: ({ column }) => sortHeader(column, "Name"),
        filterFn: "includesString",
        cell: (item) => {
          return (
            <div>
              <ProductTableItemDetailsCell item={item.row.original.productMetadata!} />
            </div>
          );
        },
      },
      {
        accessorKey: "par",
        header: ({ column }) => sortHeader(column, "PAR"),
        cell: (item) => {
          return <OrderGuideTableParCell item={item} guide={guide!} />;
        },
      },

      {
        accessorKey: "onHand",
        header: ({ column }) => sortHeader(column, "On Hand"),
        cell: (item) => {
          return <OrderGuideTableOnHandCell item={item} guide={guide!} />;
        },
      },

      {
        accessorKey: "quantity",
        header: ({ column }) => sortHeader(column, "Quantity"),
        cell: (item) => {
          return <OrderGuideTableQuantityCell item={item} guide={guide!} />;
        },
      },
      {
        accessorKey: "productMetadata.products[0].price",
        header: ({ column }) => sortHeader(column, "Price"),
        cell: (item) => <>{toCurrency(item.row.original.productMetadata?.products?.[0]?.price ?? 0)}</>,
        sortingFn: (a, b) => {
          const aTotal = a.original.productMetadata?.products?.[0]?.price ?? 0;
          const bTotal = b.original.productMetadata?.products?.[0]?.price ?? 0;

          return aTotal - bTotal;
        },
      },
      {
        id: "total",
        // header: "Total",
        header: ({ column }) => sortHeader(column, "Total"),
        sortingFn: (a, b) => {
          const aTotal = a.original.quantity * (a.original.productMetadata?.products?.[0]?.price ?? 0);
          const bTotal = b.original.quantity * (b.original.productMetadata?.products?.[0]?.price ?? 0);

          return aTotal - bTotal;
        },
        accessorFn: (item) => {
          if (!item.productMetadata!.products || item.productMetadata!.products.length === 0) return 0;
          return item.productMetadata!.products[0].price * item.quantity;
        },
        // size: 35,

        cell: (item) => {
          const initialValue = (item.row.original.quantity * (item.row.original.productMetadata?.products?.[0]?.price ?? 0)) as number;

          const [value, setValue] = useState<number>(initialValue);

          useEffect(() => {
            setValue(item.row.original.quantity * (item.row.original.productMetadata?.products?.[0]?.price ?? 0));
          }, [item.row.original.quantity, item.row.original]);

          return <>{toCurrency(value ?? 0, false)}</>;
        },
      },
      {
        id: "actions",
        header: "",
        enableSorting: false,
        meta: {
          className: "w-0",
        },
        cell: (item) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="-mx-2 px-2" onClick={() => navigate("./order", { relative: "path" })}>
                  <MoreVertical className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="cursor-pointer text-red-500 hover:bg-red-200 focus:bg-red-200 focus:text-red-600"
                  onClick={async () => {
                    const confirmed = await confirmationDialog({
                      title: "Remove Item",
                      body: (
                        <div className="flex flex-col gap-5">
                          <p>Are you sure you want to delete this item?</p>
                          <p className="font-semibold">{item.row.original.productMetadata?.name}</p>
                          <p className="font-semibold text-destructive">This action cannot be undone.</p>
                        </div>
                      ),
                      actionButton: "Delete",
                      actionVariant: "destructive",
                      cancelButton: "Cancel",
                      cancelVariant: "link",
                    });

                    if (confirmed) {
                      deleteOrderGuideItemTrigger({
                        orderGuideId: guide!.id,
                        productId: item.row.original.productMetadata!.id!,
                      });
                    }
                  }}
                >
                  {/* Delete */}
                  <Trash className="mr-2 h-4 w-4" />
                  <span>Remove</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    [guide],
  );

  // Remove the PAR and On Hand columns if the guide is in Quantity mode
  // if (guide?.type === OrderGuideType.Quantity) {
  //   columns.splice(2, 2);
  // }

  useEffect(() => {
    if (deleteOrderGuideResult.isSuccess) {
      navigate("/app/order-guide");
    }
  }, [deleteOrderGuideResult.isSuccess, navigate]);

  async function toggleOrderGuideMode() {
    const confirmed = await confirmationDialog({
      title: "Switch Order Guide Mode",
      body: (
        <div className="flex flex-col gap-5">
          <p className="font-semibold">Are you sure you want to switch to {guide?.type === OrderGuideType.Quantity ? "PAR" : "Quantity"} mode?</p>
          {guide?.type === OrderGuideType.Quantity ? (
            <>
              <p>
                PAR (Periodic Automatic Replacement) mode will allow you to set required stock levels, and our system will automatically determine the quantity
                to order based on your <span className="font-semibold">current</span> stock levels. You will need to ensure that you have set the correct PAR
                levels for each item.
              </p>
              <p>
                PAR ordering is recommended for items that you want to keep in stock at all times, such as staple items or items that are used frequently. This
                mode will also simplify the ordering process by automatically calculating the quantities to order based on your stock levels.
              </p>
            </>
          ) : (
            <>
              <p>
                Quantity mode will allow you to set the exact quantity to order for each item. This mode is recommended for items that you want to order a
                specific quantity of, regardless of your stock levels.
              </p>
              <p>
                IRN suggests using Quantity mode for items that are not used frequently, or for items that you want to order a specific quantity of, regardless
                of your stock levels. Most restaurants use Quantity mode for one-time or special order items.
              </p>
            </>
          )}

          <p className="rounded border border-destructive bg-destructive/20 p-3 font-semibold text-destructive">All quantities will be reset to 0</p>
        </div>
      ),
      actionButton: "Switch",
      actionVariant: "default",
      cancelButton: "Cancel",
      cancelVariant: "link",
    });

    if (confirmed) {
      changeOrderGuideTypeTrigger({
        orderGuideId: guide!.id,
        changeOrderGuideTypeRequest: {
          type: guide!.type === OrderGuideType.Quantity ? OrderGuideType.Par : OrderGuideType.Quantity,
        },
      });
    }
  }

  return (
    <div className="" ref={containerRef}>
      <div className="gap-4 px-4 py-4 sm:flex sm:items-center sm:px-6 lg:px-8">
        {/*<h2>{guide?.name}</h2>*/}
        <OrderGuideSelectionWidget compact />
        <div className="sm:flex-auto"></div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="relative">
            <div className="relative flex max-w-2xl items-center ">
              <MagnifyingGlassIcon className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 transform" />
              <Input
                type="text"
                name="search"
                id="search"
                className={clsx(formClasses, "pl-8 pr-3")}
                placeholder="Search"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <Button variant="default" onClick={() => navigate("./order", { relative: "path" })}>
            <ShoppingCartIcon className="mr-2 inline h-4 w-4" />
            Place Order
          </Button>
        </div>
        <div>
          {/* A "3 dot" button to show more actions to be taken on this order guide */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="-mx-2 px-2" onClick={() => navigate("./order", { relative: "path" })}>
                <MoreVertical className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Edit Order Guide</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem className="cursor-pointer" onClick={() => dispatch(setEnableParEditing(!parEditingEnabled))}>
                {/* Rename */}
                <Pencil className="mr-2 h-4 w-4" />
                {parEditingEnabled ? "Disable" : "Enable"} PAR Editing
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={async () => {
                  const newName = await inputDialog({
                    title: "Rename Order Guide",
                    body: "Enter the new name for this order guide",
                    inputProps: {
                      defaultValue: guide?.name,
                    },
                  });

                  if (newName) {
                    updateOrderGuideTrigger({
                      orderGuideId: guide!.id,
                      updateOrderGuideRequest: {
                        name: newName,
                      },
                    });
                  }
                }}
              >
                {/* Rename */}
                <Pencil className="mr-2 h-4 w-4" />
                <span>Rename</span>
              </DropdownMenuItem>
              {guide?.type === OrderGuideType.Quantity ? (
                <DropdownMenuItem className="cursor-pointer" onClick={() => toggleOrderGuideMode()}>
                  <ClipboardEdit className="mr-2 h-4 w-4" />
                  Switch to PAR
                </DropdownMenuItem>
              ) : (
                <DropdownMenuItem className="cursor-pointer" onClick={() => toggleOrderGuideMode()}>
                  <ClipboardEdit className="mr-2 h-4 w-4" />
                  Switch to Quantity
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                className="cursor-pointer text-red-500 hover:bg-red-200 focus:bg-red-200 focus:text-red-600"
                onClick={async () => {
                  const confirmed = await confirmationDialog({
                    title: "Delete Order Guide",
                    body: (
                      <div className="flex flex-col gap-5">
                        <p>Are you sure you want to delete this order guide?</p>
                        <p className="font-semibold text-destructive">This action cannot be undone.</p>
                      </div>
                    ),
                    actionButton: "Delete",
                    actionVariant: "destructive",
                    cancelButton: "Cancel",
                    cancelVariant: "link",
                  });

                  if (confirmed) {
                    deleteOrderGuideTrigger(guide!.id);
                  }
                }}
              >
                {/* Delete */}
                <Trash className="mr-2 h-4 w-4" />
                <span>Delete</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className=" px-4 sm:px-6 lg:px-8">
        <div>
          <DataTable columns={columns} data={data} />
        </div>
      </div>
      <div className="w-full py-5 text-center text-sm text-gray-500">{data.length} Rows</div>

      {/* Show the current order guide totals */}
      <div className="flex justify-end px-4 py-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-xs">
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Estimated Total</p>
            <p className="text-sm text-gray-500">{toCurrency(estimatedTotal)}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Total Items</p>
            <p className="text-sm text-gray-500">{itemCount}</p>
          </div>
        </div>
      </div>

      {/*<div>*/}
      {/*  <button onClick={() => rerender()}>Force Rerender</button>*/}
      {/*</div>*/}
    </div>
  );
}
