import { HydrateBusinesses } from "@/components/hydration/HydrateBusinesses.tsx";
import { selectUserInfo } from "@/store/Auth.Slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import posthog from "posthog-js";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function HydrationRoot({ children }: { children?: React.ReactNode }) {
  const location = useLocation();
  const user = useAppSelector(selectUserInfo);

  useEffect(() => {
    const userProps: any = {};

    if (user) {
      userProps.id = user.id;
      userProps.email = user.email;
      userProps.firstName = user.firstName;
      userProps.lastName = user.lastName;
    }

    posthog.capture("$pageview", {
      $current_url: location.pathname,
      $title: document.title,

      $set: {
        experiment_ids: [1, 2, 3],
        ...userProps,
      },

      $set_once: {
        first_visit: new Date().toISOString(),
        user: {
          id: user?.id,
        },

        email: user?.email,
      },
    });

    // posthog.setPersonProperties({
    //   experiment_ids: [1, 2, 3],
    // });
  }, [location, user]);

  return (
    <>
      <HydrateBusinesses>{children}</HydrateBusinesses>
    </>
  );
}
