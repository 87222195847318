import { DistributorConnectionHelpText } from "@/pages/app/distributors/DistributorConnectionHelpText.tsx";
import { DistributorLogins } from "@/pages/app/distributors/DistributorLogins.tsx";

export function DistributorsPage() {
  return (
    <div className="space-y-10">
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Distributor Connections</h1>
        <DistributorLogins />
      </div>

      <DistributorConnectionHelpText />
    </div>
  );
}
