import { RoleGuard } from "@/components/authentication/RoleGuard.tsx";
import { BusinessSwitcher } from "@/components/business-switcher.tsx";
import { NavMain, NavMenuGroup, NavMenuGroupItem, NavMenuLink, NavMenuSubLink } from "@/components/nav-main";
import { NavUser } from "@/components/nav-user";
import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarRail } from "@/components/ui/sidebar";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { ApplicationRole, useGetOrderGuideSummariesQuery } from "@/store/IRN.API.ts";
import { HomeIcon } from "@heroicons/react/24/outline";
import { BrowserClient, Feedback, getClient } from "@sentry/react";
import { ClipboardCheck, DollarSign, LucideArrowBigUp, MessageCircleQuestionIcon, ReplyIcon, ShoppingCart, Truck } from "lucide-react";
import * as React from "react";
import { useMemo } from "react";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const activeBusiness = useAppSelector(selectActiveBusiness);
  const allOrderGuidesQuery = useGetOrderGuideSummariesQuery();
  const sentryBrowserClient = getClient<BrowserClient>();
  const sentryFeedbackDialog = sentryBrowserClient?.getIntegrationByName<Feedback>("Feedback");

  const filteredOrderGuidesForCurrentBusiness = useMemo(() => {
    return allOrderGuidesQuery.currentData?.summaries?.filter((og) => og.businessId === activeBusiness?.id) ?? [];
  }, [allOrderGuidesQuery.currentData, activeBusiness]);

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <BusinessSwitcher />
      </SidebarHeader>
      <SidebarContent>
        {/*<SidebarGroup>*/}
        {/*  <SidebarGroupLabel>Platform</SidebarGroupLabel>*/}
        {/*  /!*<SidebarGroupAction>*!/*/}
        {/*  /!*  <Plus /> <span className="sr-only">Add Business</span>*!/*/}
        {/*  /!*</SidebarGroupAction>*!/*/}
        {/*  <SidebarGroupContent></SidebarGroupContent>*/}
        {/*</SidebarGroup>*/}
        <NavMain>
          <NavMenuGroup>
            <NavMenuGroupItem title="Dashboard" icon={HomeIcon}>
              <NavMenuSubLink title="Overview" url="/app/dashboard" />
              {/*<NavMenuSubLink title="Reports" url="/app/reports" />*/}
            </NavMenuGroupItem>
          </NavMenuGroup>

          <NavMenuGroup>
            <NavMenuGroupItem title="Order Guides" icon={ClipboardCheck}>
              <NavMenuSubLink title="All Order Guides" url="/app/order-guide" />
              {/* TODO: Insert specific order guide links here */}

              {filteredOrderGuidesForCurrentBusiness.map((og) => (
                <NavMenuSubLink key={og.id} title={og.name!} url={`/app/order-guide/${og.id}`} />
              ))}

              <NavMenuSubLink title="Uploads" url="/app/order-guide/upload" />
              <NavMenuSubLink title="Create Order Guide" url="/app/order-guide/create" />
            </NavMenuGroupItem>
          </NavMenuGroup>

          <NavMenuGroup>
            <NavMenuGroupItem title="Distributors" icon={Truck}>
              <NavMenuSubLink title="Connections" url="/app/distributors" />
            </NavMenuGroupItem>
          </NavMenuGroup>

          <NavMenuGroup>
            <NavMenuGroupItem title="Products" icon={ShoppingCart}>
              <NavMenuSubLink title="All Products" url="/app/products" />
              <RoleGuard roles={[ApplicationRole.Developer, ApplicationRole.Administrator]}>
                <NavMenuSubLink title="Create Product" url="/app/products/create" />
              </RoleGuard>
            </NavMenuGroupItem>
          </NavMenuGroup>

          <NavMenuGroup>
            <NavMenuGroupItem title="Orders" icon={DollarSign}>
              <NavMenuSubLink title="All Orders" url="/app/orders" />
              <NavMenuSubLink title="Create Order" url="/app/orders/create" />
            </NavMenuGroupItem>
          </NavMenuGroup>

          <NavMenuGroup>
            <NavMenuGroupItem title="Rebates" icon={LucideArrowBigUp}>
              <NavMenuSubLink title="My Rebates" url="/app/rebates" />
            </NavMenuGroupItem>
          </NavMenuGroup>

          <NavMenuGroup>
            <NavMenuLink title="Support" icon={MessageCircleQuestionIcon} url="https://help.irnworks.com" onClick={() => {}} />
            <NavMenuLink title="Feedback" icon={ReplyIcon} url="#" onClick={() => sentryFeedbackDialog?.openDialog()} />
          </NavMenuGroup>
        </NavMain>
        {/*<NavProjects projects={data.projects} />*/}
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
