export function SmsDisclosure() {
  return (
    <>
      {/*The opt-in location(s) must include the following: program (brand) name; link to a publicly accessible terms & conditions page; link to a publicly accessible privacy policy page; message frequency disclosure; customer care contact information; opt-out information; 'message and data rates may apply' disclosure.*/}
      <p className="text-xs pt-2">
        Message and data rates may apply. Message frequency depends on your activity. For help, text HELP. To opt-out, text STOP. View our{" "}
        <a href="/terms-of-use" target="_blank" className="text-brand-500">
          Terms & Conditions
        </a>{" "}
        and{" "}
        <a href="/privacy-policy" target="_blank" className="text-brand-500">
          Privacy Policy
        </a>
        .
      </p>
    </>
  );
}
