import { IrnSelect } from "@/components/forms/IrnInput.tsx";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Form, FormField, FormItem, FormLabel } from "@/components/ui/form.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { cn } from "@/lib/utils.ts";
import { OrderStatus, useGetOrdersQuery } from "@/store/IRN.API.ts";
import { toCurrency } from "@/utils.ts";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { endOfDay, format, startOfDay } from "date-fns";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { OrderStatusBadge } from "./utils.tsx";

export function OrderIndexPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchForm = useForm();

  const formWatch = useWatch({ control: searchForm.control });

  const statusValues = searchParams.getAll("status");
  const statusValuesAsOrderStatus = statusValues
    .filter((s) => s.toLowerCase() !== "all")
    .map((status) => status as OrderStatus)
    .filter((status) => !!status);

  const recentOrders = useGetOrdersQuery({
    startDate: searchParams.get("startDate") ?? undefined,
    endDate: searchParams.get("endDate") ?? undefined,
    skip: 0,
    take: 25,
    status: statusValuesAsOrderStatus.length > 0 ? statusValuesAsOrderStatus : undefined,
  });

  useEffect(() => {
    // console.log(formWatch);
    setSearchParams((params: URLSearchParams) => {
      if (formWatch.from) params.set("startDate", formWatch.from ? startOfDay(formWatch.from).toISOString() : "");
      if (formWatch.to) params.set("endDate", formWatch.to ? endOfDay(formWatch.to).toISOString() : "");
      if (formWatch.status) params.set("status", formWatch.status ?? "");

      return params;
    });
  }, [formWatch, setSearchParams]);

  useEffect(() => {
    if (searchParams.get("startDate")) {
      searchForm.setValue("from", new Date(searchParams.get("startDate") ?? ""));
    }
    if (searchParams.get("endDate")) {
      searchForm.setValue("to", new Date(searchParams.get("endDate") ?? ""));
    }
    if (searchParams.get("status")) {
      searchForm.setValue("status", searchParams.get("status") ?? "");
    }
  }, []);

  // We want to default to the most recent orders
  // However, we also want to let the user filter by date start/end, status, etc.
  // We should probably use a paginated table for this
  return (
    <>
      <h1 className="mb-5 text-2xl font-bold">Recent Orders</h1>
      <Form {...searchForm}>
        <form className="flex w-full justify-end space-x-8" onSubmit={searchForm.handleSubmit(() => {})}>
          <FormField
            control={searchForm.control}
            name="from"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>From</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant={"outline"} className={cn("w-[240px] pl-3 text-left font-normal", !field.value && "text-muted-foreground")}>
                      {field.value ? format(field.value ?? new Date(), "PPP") : <span>Pick a date</span>}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </FormItem>
            )}
          />
          <FormField
            control={searchForm.control}
            name="to"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>To</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant={"outline"} className={cn("w-[240px] pl-3 text-left font-normal", !field.value && "text-muted-foreground")}>
                      {field.value ? format(field.value ?? new Date(), "PPP") : <span>Pick a date</span>}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </FormItem>
            )}
          />

          <IrnSelect
            control={searchForm.control}
            placeholder="All"
            name="status"
            label="Status"
            options={[
              { label: "All", value: "all" },
              { label: "Not Placed", value: OrderStatus.NotPlaced },
              { label: "Pending", value: OrderStatus.Pending },
              { label: "Shipped", value: OrderStatus.Shipped },
              { label: "Delivered", value: OrderStatus.Delivered },
              { label: "Cancelled", value: OrderStatus.Cancelled },
              { label: "Returned", value: OrderStatus.Returned },
              { label: "Refunded", value: OrderStatus.Refunded },
              { label: "Disputed", value: OrderStatus.Disputed },
            ]}
          ></IrnSelect>
        </form>
      </Form>

      <Table className="mt-5">
        <TableCaption>
          {recentOrders.isLoading || recentOrders.isFetching ? <Skeleton className="h-5 w-full" /> : `Showing ${recentOrders.data?.orders?.length ?? 0} orders`}
        </TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Order number</TableHead>
            <TableHead>Business</TableHead>
            <TableHead>Date</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Total</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {(recentOrders.isLoading || recentOrders.isFetching) &&
            [...Array(10)].map((_, index) => (
              <TableRow key={index}>
                {[...Array(5)].map((_, index2) => (
                  <TableCell key={index2}>
                    <Skeleton className="h-5 w-full" />
                  </TableCell>
                ))}
              </TableRow>
            ))}

          {!(recentOrders.isLoading || recentOrders.isFetching) &&
            recentOrders.data?.orders?.map((order) => (
              <TableRow key={order.id}>
                <TableCell>
                  <Link to={`/app/orders/${order.id}`} className="text-brand-600 underline">
                    {order.orderId}
                  </Link>
                </TableCell>
                <TableCell>{order.business.name}</TableCell>
                <TableCell>{new Date(order.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  <OrderStatusBadge status={order.status} />
                </TableCell>
                <TableCell>{toCurrency(order.total, false)}</TableCell>
              </TableRow>
            ))}

          {!(recentOrders.isLoading || recentOrders.isFetching) && recentOrders.data?.orders?.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} className="text-center">
                No orders found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}
