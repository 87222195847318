import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { ProductsTable } from "@/components/dashboard/products/ProductsTable.tsx";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useAppDispatch } from "@/store/Hooks.ts";
import { OrderGuideUploadDto, OrderGuideUploadEntryDto, SearchFacet, useSetProductOrderGuideUploadEntryMutation } from "@/store/IRN.API.ts";
import { setCurrentEntry, setCurrentEntryProduct } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { useMemo } from "react";

export function ProductsSearchTable({
  loadingEntry,
  upload,
  entry,
}: {
  loadingEntry?: boolean;
  upload?: OrderGuideUploadDto;
  entry?: OrderGuideUploadEntryDto;
}) {
  const [selectProductTrigger] = useSetProductOrderGuideUploadEntryMutation();
  const dispatch = useAppDispatch();

  const searchFacets = useMemo(() => {
    return (entry?.potentialProductIds ?? [])?.map((productId) => ({
      facet: SearchFacet.Id,
      value: productId.toString(),
    }));
  }, [entry?.potentialProductIds]);

  const searchNameFilter = useMemo(() => {
    return (!entry?.potentialProductIds || !entry?.potentialProductIds.length) && entry?.parsedData ? JSON.parse(entry.parsedData).Name : "";
  }, [entry?.parsedData, entry?.potentialProductIds]);

  // useEffect(() => {
  //   if (selectProductResult.isSuccess) {
  //     console.log("Product selected", selectProductResult);
  //     // dispatch(gotoNextEntry(1));
  //   }
  // }, [selectProductResult]);

  // useEffect(() => {
  //   console.log(searchNameFilter);
  // }, [searchNameFilter]);

  if (loadingEntry || !upload || !entry) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <ProductsTable
        title="Potential Matches"
        clearSearchFacetsOnFilterChange={true}
        searchFacets={searchFacets}
        defaultFilter={searchNameFilter}
        actionsColumnHeader="Select matching product"
        actionsColumn={(product) => (
          <Button
            onClick={() => {
              // console.log("Selecting product", product);
              selectProductTrigger({
                orderGuideUploadId: upload.id,
                entryId: entry.id,
                setProductOrderGuideUploadEntryRequest: {
                  productMetadataId: product.id,
                },
              }).then((result) => {
                // console.log(result);
                if ("data" in result) {
                  dispatch(setCurrentEntry(result.data));
                  dispatch(setCurrentEntryProduct(product));
                }
              });
            }}
          >
            Link
          </Button>
        )}
      />

      <Alert variant="informative" dismissibleId="product-match-more-info-alert" className="mt-8">
        <QuestionMarkCircledIcon className="h-5 w-5" />
        <AlertTitle dismissibleId="product-match-more-info-alert">Don&apos;t see the product you&apos;re looking for?</AlertTitle>
        <AlertDescription>
          You can search the entire product catalog via the search bar next to the Potential Matches. If you still can&apos;t find the product you&apos;re
          looking for, you can create a custom product by clicking the &quot;Create custom product&quot; button.
        </AlertDescription>
      </Alert>
    </div>
  );
}
