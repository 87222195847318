import { UploadColumnBase } from "@/components/order-guide-upload/columns/UploadColumnBase.tsx";
import { OrderGuideUploadColumnType } from "@/store/IRN.API.ts";

/*export enum OrderGuideUploadColumnType {
  Unknown = "Unknown",
  Name = "Name",
  Description = "Description",
  Price = "Price",
  Unit = "Unit",
  Size = "Size",
  UnitSize = "UnitSize",
  Brand = "Brand",
  Category = "Category",
  CategoryId = "CategoryId",
  Mpn = "MPN",
  Upc = "UPC",
  Sku = "SKU",
  Thumbnail = "Thumbnail",
  Images = "Images",
}*/

export function UnitSizeColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      colType={OrderGuideUploadColumnType.UnitSize}
      display="Unit Size"
      disabled={disabled}
      helpBody={
        "The unit size column is used to determine the case/pack size of the item. This can be any arbitrary value, and is used only for display, and product matching. Usually this field will contain data like '1/5oz' or '5/#10 cans', etc."
      }
    />
  );
}

export function NameColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      colType={OrderGuideUploadColumnType.Name}
      disabled={disabled}
      required
      display="Name"
      helpBody={
        "The name column is used to determine the name of the item. This is a required field, and should contain the name of the item as it will be displayed in the product catalog."
      }
    />
  );
}

export function DistributorSkuColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.DistributorSku}
      required
      display="Distributor SKU"
      helpBody={
        <div className="space-y-4">
          <p>The distributor SKU column is used to determine the distributor SKU or inventory # of the item.</p>
          <p>This field is optional, however, it is recommended to include it if available.</p>

          <p>
            This field is used to match the item to the distributor catalog. We will use this field on subsequent uploads to make matching more accurate, and
            make those matches must faster.
          </p>

          <p>Some common distributor SKU fields:</p>
          <dl className="grid grid-cols-2">
            <dt className="font-semibold">Sysco</dt>
            <dd>SUPC</dd>
            <dt className="font-semibold">Kast</dt>
            <dd>Item #</dd>
            <dt className="font-semibold">Performance Food Group</dt>
            <dd>Inventory Number</dd>
          </dl>
        </div>
      }
    />
  );
}

export function BrandColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Brand}
      display="Brand"
      helpBody={
        "The brand column is used to determine the brand of the item. This field is optional, and is used to help match the item to the distributor catalog."
      }
    />
  );
}

export function ManufacturerNumberColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Manufacturer}
      display="Manufacturer #"
      helpBody={
        <div className="space-y-4">
          <p>The manufacturer # (MPN) column is used to determine the manufacturer number of the item.</p>
          <p>This field is optional, and is used to help match the item to the distributor catalog.</p>
          <p>
            MPN&apos;s are similar (and sometimes exactly) like UPC values. They are essentially an inventory # for the manufacturer. We use this value to help
            match products within a specific brand.
          </p>
          <p className="font-semibold">
            If your distributor provides a <b>UPC</b> value, please set the UPC column instead of this one. If your distributor provides both, you can set both.
          </p>
          <p>
            There are cases where you may need to extract this value from the product name/description field. Please use the checkbox at the bottom of the page
            to enable this feature.
          </p>
        </div>
      }
    />
  );
}

export function UpcColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Upc}
      display="UPC"
      helpBody={
        <div className="space-y-4">
          <p>The UPC column is used to determine the UPC of the item.</p>
          <p>This field is optional, and is used to help match the item to the distributor catalog.</p>
          <p>
            UPC&apos;s are the most common way to match products. We use this value to help match products globally. If your distributor provides an <b>MPN</b>{" "}
            value, please set the MPN column instead of this one. If your distributor provides both, you can set both.
          </p>
          <p>
            There are cases where you may need to extract this value from the product name/description field. Please use the checkbox at the bottom of the page
            to enable this feature.
          </p>
        </div>
      }
    />
  );
}

export function CategoryColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Category}
      display="Category"
      helpBody={
        "The category column is used to determine the category of the item. This field is optional, and is used to help match the item to the distributor catalog."
      }
    />
  );
}

export function DescriptionColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Description}
      display="Description"
      helpBody={
        <div className="space-y-2">
          <p>
            The description column is used to determine the description of the item. A description is usually a long string of text explaining in more detail
            what the product is. This sometimes includes things like dietary restrictions, origin information, etc.
          </p>
          <p className="text-destructive">
            You should not use this field to include the name of the product. The name of the product should be in the Name column. The description should
            contain additional information about the product.
          </p>
          <p>This field is optional, and is used only for display.</p>
        </div>
      }
    />
  );
}

export function PriceColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Price}
      display="Price"
      required
      helpBody={
        "The price column is used to determine the price of the item. This field is required, and should be the final price of the item, as it will be used to calculate the total price of the order."
      }
    />
  );
}

export function PricePerUnitColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.PricePerUnit}
      display="Price Per Unit"
      helpBody={
        "The price per unit column is used to tell us that a product is purchased by catch-weight. (i.e. the price is per pound, but the item is sold by the case)"
      }
    />
  );
}

export function UnitsPerCaseColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.UnitsPerCase}
      display="Units Per Case"
      helpBody={"The units per case column is used to determine the number of units per case of the item. (i.e. the case size)"}
    />
  );
}

export function UnitColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Unit}
      display="Unit"
      helpBody={
        "The unit column is used to determine the unit size of the item. This field is optional, and is used to help match the item to the distributor catalog. It is not commonly used. If you are unsure if you should use this field, you can leave it blank."
      }
    />
  );
}

// SizeColumn
export function SizeColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Size}
      display="Size"
      helpBody={
        "The size column is used to determine the size of the item. This field is optional, and is used to help match the item to the distributor catalog. It is not commonly used. If you are unsure if you should use this field, you can leave it blank."
      }
    />
  );
}

export function CategoryIdColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.CategoryId}
      display="Category ID"
      helpBody={
        "The category ID column is used to determine the category ID of the item. This field is optional, and is used to help match the item to the distributor catalog."
      }
    />
  );
}

export function ThumbnailColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Thumbnail}
      display="Thumbnail"
      helpBody={"The thumbnail column is used to determine the thumbnail of the item. This field is optional, and is used only for display."}
    />
  );
}

export function ImagesColumn({ disabled }: { disabled?: boolean }) {
  return (
    <UploadColumnBase
      disabled={disabled}
      colType={OrderGuideUploadColumnType.Images}
      display="Images"
      helpBody={"The images column is used to determine the images of the item. This field is optional, and is used only for display."}
    />
  );
}
