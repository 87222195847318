import { ChevronsUpDown, Plus } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from "@/components/ui/sidebar";
import { selectActiveBusiness, selectBusinesses, setActiveBusiness } from "@/store/Business.slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { BusinessDto } from "@/store/IRN.API.ts";

export function BusinessSwitcher() {
  const { isMobile } = useSidebar();
  const businesses = useAppSelector(selectBusinesses);
  const activeBusiness = useAppSelector(selectActiveBusiness) as BusinessDto;
  const dispatch = useAppDispatch();
  // const [activeTeam, setActiveTeam] = React.useState(teams[0]);

  if (!activeBusiness) {
    return null;
  }

  const getBusinessAvatarUrl = (business: BusinessDto) => {
    return `https://ui-avatars.com/api/?name=${business.name}&background=ff0000&color=fff`;
  };
  const businessAvatarUrl = getBusinessAvatarUrl(activeBusiness);

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton size="lg" className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground">
              <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                {/*<activeBusiness.logo className="size-4" />*/}
                <img src={businessAvatarUrl} alt={activeBusiness?.name} className="size-6" />
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{activeBusiness?.name}</span>
                <span className="truncate text-xs">{activeBusiness?.id}</span>
              </div>
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">Businesses</DropdownMenuLabel>
            {businesses.map((business, index) => (
              <DropdownMenuItem key={business.name} onClick={() => dispatch(setActiveBusiness(business))} className="gap-2 p-2">
                <div className="flex size-6 items-center justify-center rounded-sm border">
                  {/*<business.logo className="size-4 shrink-0" />*/}
                  <img src={getBusinessAvatarUrl(business)} alt={business.name} className="size-6 shrink-0" />
                </div>
                {business.name}
                <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem className="gap-2 p-2">
              <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                <Plus className="size-4" />
              </div>
              <div className="font-medium text-muted-foreground">Add business</div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
