import { msalPca } from "@/Security.ts";
import { AlertDialogProvider } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { Toaster } from "@/components/ui/sonner.tsx";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import "./App.css";
import { DevDataPane } from "./components/dev/DevDataPane.tsx";
import { TooltipProvider } from "./components/ui/tooltip.tsx";
import { store } from "./store/Store.ts";

import { HydrationRoot } from "@/components/hydration/HydrationRoot.tsx";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { CookiesProvider } from "react-cookie";

posthog.init(
  // new
  import.meta.env.VITE_POSTHOG_KEY,
  {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
  },
);

function AppPage() {
  return (
    <CookiesProvider
      defaultSetOptions={{
        path: "/",
      }}
    >
      <PostHogProvider client={posthog}>
        <Provider store={store}>
          {/*<PersistGate loading={null} persistor={persistor}>*/}
          <TooltipProvider delayDuration={250} skipDelayDuration={0}>
            <AlertDialogProvider>
              {/*<FloatingActionsButtonWrapper />*/}
              <DevDataPane />
              <MsalProvider instance={msalPca}>
                <HydrationRoot>
                  <Outlet />
                </HydrationRoot>
              </MsalProvider>
              <Toaster
                expand={true}
                position="bottom-right"
                // richColors
                closeButton
                theme="light"
                richColors
                visibleToasts={5}
                toastOptions={{
                  duration: 5000,
                }}
              />
            </AlertDialogProvider>
          </TooltipProvider>
          {/*</PersistGate>*/}
        </Provider>
      </PostHogProvider>
    </CookiesProvider>
  );
}

export const App = AppPage;
