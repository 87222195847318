import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter, AlertDialogTitle } from "@/components/ui/alert-dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table.tsx";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import {
  DistributorLoginDto,
  DistributorOrderGuideSummary,
  useCreateBusinessDistributorLoginMutation,
  useDeleteBusinessDistributorLoginMutation,
  useGetBusinessDistributorLoginQuery,
  useGetDistributorsQuery,
  useUpdateBusinessDistributorLoginMutation,
} from "@/store/IRN.API.ts";
import { isProblemDetailsApiError } from "@/utils.ts";
import Lottie from "lottie-react";
import { Pencil, Trash2 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import loginAnimation from "./login-lottie.json";
import successCheckAnimation from "./success-check-lottie.json";

const SUPPORTED_DISTRIBUTORS = [
  {
    name: "Sysco",
    // logo: syscoLogo,
    supported: true,
  },
  {
    name: "Kast",
    // logoUrl:
    //   "https://static.wixstatic.com/media/f75fc4_a2656c874e114f499de86220307e1e46~mv2.png/v1/fill/w_245,h_68,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/1-2020%20KAST%20DISTRIBUTORS%20LOGO%20transparen.png",
    supported: false,
  },
  {
    name: "Performance Food Group",
    // logo: pfgLogo,
    supported: false,
  },
];

type DistributorLogin = DistributorLoginDto & {
  name: string;
  logoUrl: string;
  distributorId: number;
};

export function DistributorLogins() {
  const activeBusiness = useAppSelector(selectActiveBusiness);
  const businessId = activeBusiness?.id ?? 0;
  const getDistributorsQuery = useGetDistributorsQuery(businessId, { skip: !businessId });
  const { data: distributorLogins, refetch } = useGetBusinessDistributorLoginQuery(businessId, { skip: !businessId });

  const [deleteLoginMutation /*, deleteLoginMutationData*/] = useDeleteBusinessDistributorLoginMutation();
  const [updateLoginMutation /*, updateLoginMutationData*/] = useUpdateBusinessDistributorLoginMutation();

  const availableDistributors = getDistributorsQuery.data?.distributors;

  const distributors: DistributorLogin[] = useMemo(() => {
    if (!distributorLogins || !availableDistributors) return [];
    return distributorLogins.map((d) => {
      const distributor = availableDistributors.find((ad) => ad.id === d.distributorId);
      return {
        ...d,
        name: distributor?.name ?? "",
        logoUrl: distributor?.imageUrl ?? "",
        distributorId: distributor?.id ?? 0,
      };
    });
  }, [availableDistributors, distributorLogins]);

  const [editingDistributor, setEditingDistributor] = useState<DistributorLogin | null>(null);

  const handleEdit = (distributor: DistributorLogin) => {
    setEditingDistributor(distributor);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteLoginMutation({ businessId: businessId, distributorLoginId: id }).unwrap();
      toast.success("The distributor login has been successfully removed.");
      refetch();
    } catch (error) {
      toast.error("Failed to delete distributor login. Please try again.");
    }
  };

  const handleSave = async () => {
    if (editingDistributor) {
      try {
        await updateLoginMutation({
          businessId: businessId,
          distributorLoginId: editingDistributor.id,
          updateBusinessDistributorLoginRequest: {
            username: editingDistributor.username,
            password: editingDistributor.password,
          },
        }).unwrap();
        toast.success("The distributor login has been successfully updated.");
        setEditingDistributor(null);
        refetch();
      } catch (error) {
        toast.error("Failed to update distributor login. Please try again.");
      }
    }
  };

  return (
    <>
      <div className="mb-4">
        <CreateDistributorLoginFlow businessId={businessId} />
      </div>
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Username</TableHead>
              {editingDistributor && <TableHead>Password</TableHead>}
              <TableHead>Date Added</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {distributors?.length > 0 ? (
              distributors!.map((distributor) => (
                <TableRow key={distributor.id}>
                  <TableCell>
                    {distributor.logoUrl && <img src={distributor.logoUrl} alt={`${distributor.name} logo`} width={64} height={64} className="" />}
                  </TableCell>
                  <TableCell>{distributor.name}</TableCell>
                  <TableCell>
                    {editingDistributor?.id === distributor.id ? (
                      <Input value={editingDistributor.username} onChange={(e) => setEditingDistributor({ ...editingDistributor, username: e.target.value })} />
                    ) : (
                      distributor.username
                    )}
                  </TableCell>
                  {editingDistributor?.id === distributor.id && (
                    <TableCell>
                      <div className="flex items-center">
                        <Input
                          type="password"
                          value={editingDistributor.password}
                          onChange={(e) => setEditingDistributor({ ...editingDistributor, password: e.target.value })}
                        />
                      </div>
                    </TableCell>
                  )}
                  <TableCell>{new Date(distributor.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>
                    {editingDistributor?.id === distributor.id ? (
                      <Button onClick={handleSave}>Save</Button>
                    ) : (
                      <div className="flex space-x-2">
                        <Button size="icon" variant="icon" onClick={() => handleEdit(distributor)}>
                          <Pencil className="h-3 w-3" />
                          <span className="sr-only">Edit</span>
                        </Button>
                        <Button size="icon" onClick={() => handleDelete(distributor.id)}>
                          <Trash2 className="h-3 w-3" />
                          <span className="sr-only">Delete</span>
                        </Button>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} className="text-center">
                  No distributor logins found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

function CreateDistributorLoginFlow({ businessId }: { businessId: number }) {
  const [createLoginMutation, createLoginMutationData] = useCreateBusinessDistributorLoginMutation();
  const { refetch: refetchLogins } = useGetBusinessDistributorLoginQuery(businessId, { skip: !businessId });

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [newDistributor, setNewDistributor] = useState<Partial<DistributorLogin>>({
    username: import.meta.env.VITE_DISTRIBUTOR_LOGIN_USERNAME ?? null,
    password: import.meta.env.VITE_DISTRIBUTOR_LOGIN_PASSWORD ?? null,
  });
  const [newDistributorType, setNewDistributorType] = useState<string | null>(import.meta.env.VITE_DISTRIBUTOR_LOGIN_DEFAULT_TYPE ?? null);

  const [flowState, setFlowState] = useState<"add" | "verify" | "import" | "complete" | "error">("add");
  const [availableOrderGuides, setAvailableOrderGuides] = useState<DistributorOrderGuideSummary[]>([]);
  const [selectedImportOrderGuides, setSelectedImportOrderGuides] = useState<DistributorOrderGuideSummary[]>([]);

  const isAlertDialogState = () => flowState !== "add";

  useEffect(() => {
    // return;
    if (!createLoginMutationData.isLoading) {
      if (createLoginMutationData.error) {
        setFlowState("error");
      } else {
        if (!createLoginMutationData.data) {
          setFlowState("add");
          return;
        }
        // If there's data, and order guides available, ask to import any
        if (createLoginMutationData.data && createLoginMutationData.data.availableOrderGuides.length > 0) {
          console.log(createLoginMutationData.data);
          setFlowState("import");
          setAvailableOrderGuides(createLoginMutationData.data.availableOrderGuides);
        } else {
          setFlowState("complete");
        }
      }
    } else {
      // If we're loading, then we should show the verify state (loading spinner & message)
      setFlowState("verify");
      setIsAddDialogOpen(false);
    }
  }, [createLoginMutationData.isLoading, createLoginMutationData.error, createLoginMutationData.data]);

  const handleAdd = async () => {
    if (
      newDistributor.username &&
      newDistributor.password &&
      // Make sure the password has a valid value (not empty/all asterisks)
      newDistributor.password.replace(/\*/g, "").length > 0 &&
      newDistributorType
    ) {
      setIsAddDialogOpen(false);
      await createLoginMutation({
        businessId: businessId,
        // distributorId: newDistributor.distributorId ?? 0,
        createBusinessDistributorLoginRequest: {
          username: newDistributor.username,

          password: newDistributor.password,
          distributorType: newDistributorType ?? "",
        },
      }).then(() => {
        toast.success("The new distributor login has been successfully added.");
        setNewDistributor({});

        // setIsAddDialogOpen(false);
        refetchLogins();
      });
    }
  };

  const importSelectedOrderGuides = async () => {
    console.log(selectedImportOrderGuides);
    // await importOrderGuidesMutation({ businessId: businessId, orderGuides: selectedOrderGuides });
    setFlowState("complete");
  };

  return (
    <>
      <Button onClick={() => setIsAddDialogOpen(true)}>Add New Distributor Login</Button>
      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Distributor Login</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="distributorId" className="text-right">
                Distributor
              </Label>
              <Select
                defaultValue={newDistributorType || undefined}
                onValueChange={(e) => {
                  setNewDistributorType(e);
                  // setNewDistributor({ ...newDistributor, distributorId: Number(e) });
                }}
              >
                <SelectTrigger className="col-span-3">
                  <SelectValue placeholder="Select a distributor" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="0" disabled>
                    Select a distributor
                  </SelectItem>
                  {SUPPORTED_DISTRIBUTORS?.map((distributor) => (
                    <SelectItem key={distributor.name} value={distributor.name.toString()} disabled={!distributor.supported}>
                      {distributor.name} {!distributor.supported && <span className="text-gray-500 ml-2">(Coming soon)</span>}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="username" className="text-right">
                Username
              </Label>
              <Input
                id="username"
                value={newDistributor.username || ""}
                onChange={(e) => setNewDistributor({ ...newDistributor, username: e.target.value })}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="password" className="text-right">
                Password
              </Label>
              <Input
                id="password"
                type="password"
                value={newDistributor.password || ""}
                onChange={(e) => setNewDistributor({ ...newDistributor, password: e.target.value })}
                className="col-span-3"
              />
            </div>
          </div>
          <Button onClick={handleAdd}>Add Distributor Login</Button>
        </DialogContent>
      </Dialog>

      <AlertDialog open={isAlertDialogState()}>
        <AlertDialogContent>
          {flowState === "verify" && (
            <>
              <AlertDialogTitle className="text-center text-xl">Verifying login</AlertDialogTitle>
              <div className="grid place-items-center w-full">
                {/*<LoadingSpinner size="4xl" alignment="center" />*/}
                <div className="w-64 h-64">
                  <Lottie animationData={loginAnimation} loop={true} />
                </div>
                <p className="text-center">
                  Please wait while we verify your login, and gather your order guides. This may take a few moments. Please do not leave this page while this
                  process is running.
                </p>
              </div>
            </>
          )}
          {flowState === "import" && (
            <>
              <AlertDialogTitle>Order Guides Found</AlertDialogTitle>
              <div className="grid place-items-center w-full gap-10">
                <p>
                  We found <strong>{availableOrderGuides.length}</strong> order guides for this distributor. Please select the order guides you would like to
                  import.
                </p>
                <div className="grid gap-4">
                  {availableOrderGuides.map((orderGuide) => (
                    <div key={orderGuide.name} className="flex items-center space-x-2">
                      <Checkbox
                        id={orderGuide.name}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setSelectedImportOrderGuides([...selectedImportOrderGuides, orderGuide]);
                          } else {
                            setSelectedImportOrderGuides(selectedImportOrderGuides.filter((og) => og.name !== orderGuide.name));
                          }
                        }}
                      />
                      <label htmlFor={orderGuide.name} className="text-sm font-medium leading-none">
                        {orderGuide.name} ({orderGuide.numberOfItems} items)
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => setFlowState("complete")}>Skip Import</AlertDialogCancel>
                <AlertDialogAction onClick={() => importSelectedOrderGuides()}>Import Selected Order Guides</AlertDialogAction>
              </AlertDialogFooter>
            </>
          )}
          {flowState === "error" && (
            <>
              <AlertDialogTitle>Error</AlertDialogTitle>
              <div className="grid place-items-center w-full gap-10">
                <p>There was an error logging into the distributor. Please try again.</p>
                {isProblemDetailsApiError(createLoginMutationData.error) && (
                  <p className="text-sm">{createLoginMutationData.error.data.errors?.map((error) => <span key={error.name}>{error.reason}</span>)}</p>
                )}
              </div>
              <AlertDialogFooter>
                <AlertDialogAction
                  onClick={() => {
                    setFlowState("add");
                    setIsAddDialogOpen(true);
                  }}
                >
                  Close
                </AlertDialogAction>
              </AlertDialogFooter>
            </>
          )}
          {flowState === "complete" && (
            <>
              <div className="grid place-items-center w-full gap-10 mt-10">
                <div className="w-32 h-32">
                  <Lottie animationData={successCheckAnimation} loop={false} />
                </div>
                <p>The new distributor login has been successfully added.</p>
              </div>

              <AlertDialogFooter>
                <AlertDialogAction
                  onClick={() => {
                    setFlowState("add");
                    setIsAddDialogOpen(false);
                  }}
                >
                  Close
                </AlertDialogAction>
              </AlertDialogFooter>
            </>
          )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
