import { AuthHydration } from "@/components/authentication/AuthHydration.tsx";
import { AuthWrapper } from "@/components/authentication/AuthWrapper.tsx";
import { MainSidebarLayout } from "@/components/layout/MainSidebarLayout.tsx";

export function AppContainer() {
  return (
    // <MsalProvider instance={msalPca}>
    <AuthWrapper>
      <AuthHydration />
      {/*<MainLayout />*/}
      <MainSidebarLayout />
    </AuthWrapper>
    // </MsalProvider>
  );
}

export function AppContainerNoSidebar({ children }: { children: React.ReactNode }) {
  return (
    // <MsalProvider instance={msalPca}>
    <AuthWrapper>
      <AuthHydration />
      <main className="w-full">
        <div className="md:p-10 p-1">{children}</div>
      </main>
    </AuthWrapper>
    // </MsalProvider>
  );
}
