import { apiSlice as api } from "./API.slice";
export const addTagTypes = [
  "distributor-logins",
  "business",
  "distributor",
  "order-guide",
  "order-guide-upload",
  "order-guide-upload-entry",
  "orders",
  "payment",
  "product",
  "contact-us",
  "user",
  "users",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getHealthz: build.query<GetHealthzApiResponse, GetHealthzApiArg>({
        query: () => ({ url: `/-/healthz` }),
      }),
      createBusinessDistributorLogin: build.mutation<CreateBusinessDistributorLoginApiResponse, CreateBusinessDistributorLoginApiArg>({
        query: (queryArg) => ({
          url: `/api/business/${queryArg.businessId}/distributor/login`,
          method: "POST",
          body: queryArg.createBusinessDistributorLoginRequest,
        }),
        invalidatesTags: ["distributor-logins"],
      }),
      createBusiness: build.mutation<CreateBusinessApiResponse, CreateBusinessApiArg>({
        query: (queryArg) => ({ url: `/api/business`, method: "POST", body: queryArg }),
        invalidatesTags: ["business"],
      }),
      getBusinesses: build.query<GetBusinessesApiResponse, GetBusinessesApiArg>({
        query: () => ({ url: `/api/business` }),
        providesTags: ["business"],
      }),
      deleteBusinessDistributorLogin: build.mutation<DeleteBusinessDistributorLoginApiResponse, DeleteBusinessDistributorLoginApiArg>({
        query: (queryArg) => ({ url: `/api/business/${queryArg.businessId}/distributor-logins/${queryArg.distributorLoginId}`, method: "DELETE" }),
        invalidatesTags: ["distributor-logins"],
      }),
      updateBusinessDistributorLogin: build.mutation<UpdateBusinessDistributorLoginApiResponse, UpdateBusinessDistributorLoginApiArg>({
        query: (queryArg) => ({
          url: `/api/business/${queryArg.businessId}/distributor-logins/${queryArg.distributorLoginId}`,
          method: "PUT",
          body: queryArg.updateBusinessDistributorLoginRequest,
        }),
        invalidatesTags: ["distributor-logins"],
      }),
      deleteBusiness: build.mutation<DeleteBusinessApiResponse, DeleteBusinessApiArg>({
        query: (queryArg) => ({ url: `/api/business/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["business"],
      }),
      getBusiness: build.query<GetBusinessApiResponse, GetBusinessApiArg>({
        query: (queryArg) => ({ url: `/api/business/${queryArg}` }),
        providesTags: ["business"],
      }),
      updateBusiness: build.mutation<UpdateBusinessApiResponse, UpdateBusinessApiArg>({
        query: (queryArg) => ({ url: `/api/business/${queryArg.businessId}`, method: "PUT", body: queryArg.createOrUpdateBusinessRequest }),
        invalidatesTags: ["business"],
      }),
      getBusinessDistributorLogin: build.query<GetBusinessDistributorLoginApiResponse, GetBusinessDistributorLoginApiArg>({
        query: (queryArg) => ({ url: `/api/business/${queryArg}/distributor-logins` }),
        providesTags: ["distributor-logins"],
      }),
      getBusinessStats: build.query<GetBusinessStatsApiResponse, GetBusinessStatsApiArg>({
        query: (queryArg) => ({ url: `/api/business/${queryArg}/stats` }),
        providesTags: ["business"],
      }),
      createDistributor: build.mutation<CreateDistributorApiResponse, CreateDistributorApiArg>({
        query: (queryArg) => ({ url: `/api/distributor`, method: "POST", body: queryArg }),
        invalidatesTags: ["distributor"],
      }),
      getDistributors: build.query<GetDistributorsApiResponse, GetDistributorsApiArg>({
        query: (queryArg) => ({ url: `/api/distributor`, params: { businessId: queryArg } }),
        providesTags: ["distributor"],
      }),
      verifyDistributorOtp: build.mutation<VerifyDistributorOtpApiResponse, VerifyDistributorOtpApiArg>({
        query: (queryArg) => ({ url: `/api/distributor/otp/verify`, method: "POST", body: queryArg }),
        invalidatesTags: ["distributor"],
      }),
      addProductToOrderGuide: build.mutation<AddProductToOrderGuideApiResponse, AddProductToOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg.orderGuideId}/product`, method: "POST", body: queryArg.addProductToOrderGuideRequest }),
        invalidatesTags: ["order-guide"],
      }),
      changeOrderGuideType: build.mutation<ChangeOrderGuideTypeApiResponse, ChangeOrderGuideTypeApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg.orderGuideId}/type`, method: "PUT", body: queryArg.changeOrderGuideTypeRequest }),
        invalidatesTags: ["order-guide"],
      }),
      createOrderFromOrderGuide: build.mutation<CreateOrderFromOrderGuideApiResponse, CreateOrderFromOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg}/order`, method: "POST" }),
        invalidatesTags: ["order-guide"],
      }),
      createOrderGuide: build.mutation<CreateOrderGuideApiResponse, CreateOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide`, method: "POST", body: queryArg }),
        invalidatesTags: ["order-guide"],
      }),
      createOrderGuideItemCategory: build.mutation<CreateOrderGuideItemCategoryApiResponse, CreateOrderGuideItemCategoryApiArg>({
        query: (queryArg) => ({
          url: `/api/order-guide/${queryArg.orderGuideId}/category`,
          method: "POST",
          body: queryArg.createOrderGuideItemCategoryRequest,
        }),
        invalidatesTags: ["order-guide"],
      }),
      deleteOrderGuide: build.mutation<DeleteOrderGuideApiResponse, DeleteOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["order-guide"],
      }),
      getOrderGuideById: build.query<GetOrderGuideByIdApiResponse, GetOrderGuideByIdApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg}` }),
        providesTags: ["order-guide"],
      }),
      updateOrderGuide: build.mutation<UpdateOrderGuideApiResponse, UpdateOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg.orderGuideId}`, method: "PUT", body: queryArg.updateOrderGuideRequest }),
        invalidatesTags: ["order-guide"],
      }),
      deleteOrderGuideItemCategory: build.mutation<DeleteOrderGuideItemCategoryApiResponse, DeleteOrderGuideItemCategoryApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg.orderGuideId}/category/${queryArg.categoryId}`, method: "DELETE" }),
        invalidatesTags: ["order-guide"],
      }),
      getOrderGuideDisplay: build.query<GetOrderGuideDisplayApiResponse, GetOrderGuideDisplayApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg}/display` }),
        providesTags: ["order-guide"],
      }),
      getOrderGuideSummaries: build.query<GetOrderGuideSummariesApiResponse, GetOrderGuideSummariesApiArg>({
        query: () => ({ url: `/api/order-guide/summaries` }),
        providesTags: ["order-guide"],
      }),
      removeProductFromOrderGuide: build.mutation<RemoveProductFromOrderGuideApiResponse, RemoveProductFromOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg.orderGuideId}/product/${queryArg.productId}`, method: "DELETE" }),
        invalidatesTags: ["order-guide"],
      }),
      reorderOrderGuideCategories: build.mutation<ReorderOrderGuideCategoriesApiResponse, ReorderOrderGuideCategoriesApiArg>({
        query: (queryArg) => ({
          url: `/api/order-guide/${queryArg.orderGuideId}/categories/reorder`,
          method: "POST",
          body: queryArg.reorderOrderGuideCategoriesRequest,
        }),
        invalidatesTags: ["order-guide"],
      }),
      reorderOrderGuideItem: build.mutation<ReorderOrderGuideItemApiResponse, ReorderOrderGuideItemApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/${queryArg.orderGuideId}/items/reorder`, method: "POST", body: queryArg.reorderOrderGuideItemRequest }),
        invalidatesTags: ["order-guide"],
      }),
      setProductQuantityInOrderGuide: build.mutation<SetProductQuantityInOrderGuideApiResponse, SetProductQuantityInOrderGuideApiArg>({
        query: (queryArg) => ({
          url: `/api/order-guide/${queryArg.orderGuideId}/product/${queryArg.productId}/quantity`,
          method: "POST",
          body: queryArg.setProductQuantityInOrderGuideRequest,
        }),
        invalidatesTags: ["order-guide"],
      }),
      confirmOrderGuideUpload: build.mutation<ConfirmOrderGuideUploadApiResponse, ConfirmOrderGuideUploadApiArg>({
        query: (queryArg) => ({
          url: `/api/order-guide/upload/${queryArg.orderGuideUploadId}/confirm`,
          method: "POST",
          body: queryArg.confirmOrderGuideUploadRequest,
        }),
        invalidatesTags: ["order-guide-upload", "order-guide"],
      }),
      deleteOrderGuideUpload: build.mutation<DeleteOrderGuideUploadApiResponse, DeleteOrderGuideUploadApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/upload/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["order-guide-upload", "order-guide"],
      }),
      getOrderGuideUpload: build.query<GetOrderGuideUploadApiResponse, GetOrderGuideUploadApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/upload/${queryArg}` }),
        providesTags: ["order-guide-upload", "order-guide"],
      }),
      confirmOrderGuideUploadEntry: build.mutation<ConfirmOrderGuideUploadEntryApiResponse, ConfirmOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({
          url: `/api/order-guide/upload/${queryArg.orderGuideUploadId}/confirmEntry`,
          method: "POST",
          body: queryArg.confirmOrderGuideUploadEntryRequest,
        }),
        invalidatesTags: ["order-guide-upload", "order-guide-upload-entry", "order-guide"],
      }),
      createProductFromOrderGuideUploadEntry: build.mutation<CreateProductFromOrderGuideUploadEntryApiResponse, CreateProductFromOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/upload/${queryArg.orderGuideUploadId}/entry/${queryArg.entryId}/create-product`, method: "POST" }),
        invalidatesTags: ["order-guide-upload", "order-guide-upload-entry", "order-guide"],
      }),
      getOrderGuideUploadEntry: build.query<GetOrderGuideUploadEntryApiResponse, GetOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/upload/${queryArg.orderGuideUploadId}/entry/${queryArg.entryId}` }),
        providesTags: ["order-guide-upload", "order-guide-upload-entry", "order-guide"],
      }),
      setProductOrderGuideUploadEntry: build.mutation<SetProductOrderGuideUploadEntryApiResponse, SetProductOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({
          url: `/api/order-guide/upload/${queryArg.orderGuideUploadId}/entry/${queryArg.entryId}/set-product`,
          method: "POST",
          body: queryArg.setProductOrderGuideUploadEntryRequest,
        }),
        invalidatesTags: ["order-guide-upload", "order-guide-upload-entry", "order-guide"],
      }),
      unlinkOrderGuideUploadEntry: build.mutation<UnlinkOrderGuideUploadEntryApiResponse, UnlinkOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/upload/${queryArg.orderGuideUploadId}/entry/${queryArg.entryId}/unlink`, method: "POST" }),
        invalidatesTags: ["order-guide-upload", "order-guide-upload-entry", "order-guide"],
      }),
      getOrderGuideUploadColumnTypes: build.query<GetOrderGuideUploadColumnTypesApiResponse, GetOrderGuideUploadColumnTypesApiArg>({
        query: () => ({ url: `/api/order-guide/upload/column-types` }),
        providesTags: ["order-guide"],
      }),
      getOrderGuideUploadSummaries: build.query<GetOrderGuideUploadSummariesApiResponse, GetOrderGuideUploadSummariesApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/upload/${queryArg}/summaries` }),
        providesTags: ["order-guide-upload", "order-guide"],
      }),
      uploadOrderGuide: build.mutation<UploadOrderGuideApiResponse, UploadOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/order-guide/upload/${queryArg.businessId}`, method: "POST", body: queryArg.uploadOrderGuideRequest }),
        invalidatesTags: ["order-guide-upload", "order-guide"],
      }),
      completeOrder: build.mutation<CompleteOrderApiResponse, CompleteOrderApiArg>({
        query: (queryArg) => ({ url: `/api/orders/${queryArg}/complete`, method: "POST" }),
        invalidatesTags: ["orders"],
      }),
      getOrderDetailsById: build.query<GetOrderDetailsByIdApiResponse, GetOrderDetailsByIdApiArg>({
        query: (queryArg) => ({ url: `/api/orders/${queryArg}` }),
        providesTags: ["orders"],
      }),
      getOrders: build.query<GetOrdersApiResponse, GetOrdersApiArg>({
        query: (queryArg) => ({
          url: `/api/orders`,
          params: {
            take: queryArg.take,
            skip: queryArg.skip,
            status: queryArg.status,
            endDate: queryArg.endDate,
            startDate: queryArg.startDate,
            businessIds: queryArg.businessIds,
          },
        }),
        providesTags: ["orders"],
      }),
      getRecentOrders: build.query<GetRecentOrdersApiResponse, GetRecentOrdersApiArg>({
        query: (queryArg) => ({ url: `/api/orders/recent/${queryArg.businessId}`, params: { take: queryArg.take } }),
        providesTags: ["orders"],
      }),
      createPaymentMethod: build.mutation<CreatePaymentMethodApiResponse, CreatePaymentMethodApiArg>({
        query: (queryArg) => ({ url: `/api/payment/${queryArg.businessId}/method`, method: "POST", body: queryArg.createPaymentMethodRequest }),
        invalidatesTags: ["payment"],
      }),
      getBusinessPaymentMethods: build.query<GetBusinessPaymentMethodsApiResponse, GetBusinessPaymentMethodsApiArg>({
        query: (queryArg) => ({ url: `/api/payment/${queryArg}/method` }),
        providesTags: ["payment"],
      }),
      deletePaymentMethod: build.mutation<DeletePaymentMethodApiResponse, DeletePaymentMethodApiArg>({
        query: (queryArg) => ({ url: `/api/payment/${queryArg.businessId}/method/${queryArg.paymentMethodId}`, method: "DELETE" }),
        invalidatesTags: ["payment"],
      }),
      submitPayment: build.mutation<SubmitPaymentApiResponse, SubmitPaymentApiArg>({
        query: (queryArg) => ({ url: `/api/payment/${queryArg.businessId}/submit`, method: "POST", body: queryArg.submitPaymentRequest }),
        invalidatesTags: ["payment"],
      }),
      getProductMetadata: build.query<GetProductMetadataApiResponse, GetProductMetadataApiArg>({
        query: (queryArg) => ({ url: `/api/product/${queryArg.productId}`, params: { includePendingUploadProducts: queryArg.includePendingUploadProducts } }),
        providesTags: ["product"],
      }),
      getProductPriceHistory: build.query<GetProductPriceHistoryApiResponse, GetProductPriceHistoryApiArg>({
        query: (queryArg) => ({ url: `/api/product/${queryArg}/price-history` }),
        providesTags: ["product"],
      }),
      getSearchProductFacets: build.query<GetSearchProductFacetsApiResponse, GetSearchProductFacetsApiArg>({
        query: (queryArg) => ({ url: `/api/product/search/facets`, params: { businessId: queryArg } }),
        providesTags: ["product"],
      }),
      irnapiEndpointsPublicContactUsEndpoint: build.mutation<IrnapiEndpointsPublicContactUsEndpointApiResponse, IrnapiEndpointsPublicContactUsEndpointApiArg>({
        query: (queryArg) => ({ url: `/api/contact-us`, method: "POST", body: queryArg }),
        invalidatesTags: ["contact-us"],
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: () => ({ url: `/api/user` }),
        providesTags: ["user"],
      }),
      getUserRoles: build.query<GetUserRolesApiResponse, GetUserRolesApiArg>({
        query: () => ({ url: `/api/user/auth/roles` }),
        providesTags: ["user"],
      }),
      listUsers: build.query<ListUsersApiResponse, ListUsersApiArg>({
        query: () => ({ url: `/api/users` }),
        providesTags: ["users"],
      }),
      onboardUser: build.mutation<OnboardUserApiResponse, OnboardUserApiArg>({
        query: (queryArg) => ({ url: `/api/user/onboard`, method: "POST", body: queryArg }),
        invalidatesTags: ["user"],
      }),
      updateUserInfo: build.mutation<UpdateUserInfoApiResponse, UpdateUserInfoApiArg>({
        query: (queryArg) => ({ url: `/api/user/contact-info`, method: "PUT", body: queryArg }),
        invalidatesTags: ["user"],
      }),
      searchProducts: build.mutation<SearchProductsApiResponse, SearchProductsApiArg>({
        query: (queryArg) => ({ url: `/api/product/search`, method: "POST", body: queryArg }),
        invalidatesTags: ["product"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as irnApi };
export type GetHealthzApiResponse = unknown;
export type GetHealthzApiArg = void;
export type CreateBusinessDistributorLoginApiResponse = /** status 200 Success */ CreateBusinessDistributorLoginResponse;
export type CreateBusinessDistributorLoginApiArg = {
  businessId: number;
  createBusinessDistributorLoginRequest: CreateBusinessDistributorLoginRequest;
};
export type CreateBusinessApiResponse = /** status 200 Success */ BusinessDto;
export type CreateBusinessApiArg = CreateOrUpdateBusinessRequest;
export type GetBusinessesApiResponse = /** status 200 Success */ BusinessDto[];
export type GetBusinessesApiArg = void;
export type DeleteBusinessDistributorLoginApiResponse = unknown;
export type DeleteBusinessDistributorLoginApiArg = {
  businessId: number;
  distributorLoginId: number;
};
export type UpdateBusinessDistributorLoginApiResponse = /** status 200 Success */ DistributorLoginDto;
export type UpdateBusinessDistributorLoginApiArg = {
  businessId: number;
  distributorLoginId: number;
  updateBusinessDistributorLoginRequest: UpdateBusinessDistributorLoginRequest;
};
export type DeleteBusinessApiResponse = /** status 204 No Content */ void;
export type DeleteBusinessApiArg = number;
export type GetBusinessApiResponse = /** status 200 Success */ BusinessDto;
export type GetBusinessApiArg = number;
export type UpdateBusinessApiResponse = /** status 200 Success */ BusinessDto;
export type UpdateBusinessApiArg = {
  businessId: number;
  createOrUpdateBusinessRequest: CreateOrUpdateBusinessRequest;
};
export type GetBusinessDistributorLoginApiResponse = /** status 200 Success */ DistributorLoginDto[];
export type GetBusinessDistributorLoginApiArg = number;
export type GetBusinessStatsApiResponse = /** status 200 Success */ BusinessStat[];
export type GetBusinessStatsApiArg = number;
export type CreateDistributorApiResponse = /** status 200 Success */ CreateDistributorResponse;
export type CreateDistributorApiArg = CreateDistributorRequest;
export type GetDistributorsApiResponse = /** status 200 Success */ GetDistributorsResponse;
export type GetDistributorsApiArg = number | null;
export type VerifyDistributorOtpApiResponse = /** status 200 Success */ VerifyDistributorOtpResponse;
export type VerifyDistributorOtpApiArg = VerifyDistributorOtpRequest;
export type AddProductToOrderGuideApiResponse = /** status 200 Success */ AddProductToOrderGuideResponse;
export type AddProductToOrderGuideApiArg = {
  orderGuideId: number;
  addProductToOrderGuideRequest: AddProductToOrderGuideRequest;
};
export type ChangeOrderGuideTypeApiResponse = unknown;
export type ChangeOrderGuideTypeApiArg = {
  orderGuideId: number;
  changeOrderGuideTypeRequest: ChangeOrderGuideTypeRequest;
};
export type CreateOrderFromOrderGuideApiResponse = /** status 200 Success */ CreateOrderFromOrderGuideResponse;
export type CreateOrderFromOrderGuideApiArg = number;
export type CreateOrderGuideApiResponse = /** status 200 Success */ CreateOrderGuideResponse;
export type CreateOrderGuideApiArg = CreateOrderGuideRequest;
export type CreateOrderGuideItemCategoryApiResponse = /** status 200 Success */ CreateOrderGuideItemCategoryResponse;
export type CreateOrderGuideItemCategoryApiArg = {
  orderGuideId: number;
  createOrderGuideItemCategoryRequest: CreateOrderGuideItemCategoryRequest;
};
export type DeleteOrderGuideApiResponse = unknown;
export type DeleteOrderGuideApiArg = number;
export type GetOrderGuideByIdApiResponse = /** status 200 Success */ GetOrderGuideByIdResponse;
export type GetOrderGuideByIdApiArg = number;
export type UpdateOrderGuideApiResponse = /** status 200 Success */ UpdateOrderGuideResponse;
export type UpdateOrderGuideApiArg = {
  orderGuideId: number;
  updateOrderGuideRequest: UpdateOrderGuideRequest;
};
export type DeleteOrderGuideItemCategoryApiResponse = unknown;
export type DeleteOrderGuideItemCategoryApiArg = {
  orderGuideId: number;
  categoryId: number;
};
export type GetOrderGuideDisplayApiResponse = /** status 200 Success */ GetOrderGuideDisplayResponse;
export type GetOrderGuideDisplayApiArg = number;
export type GetOrderGuideSummariesApiResponse = /** status 200 Success */ GetOrderGuideSummariesResponse;
export type GetOrderGuideSummariesApiArg = void;
export type RemoveProductFromOrderGuideApiResponse = /** status 204 No Content */ void;
export type RemoveProductFromOrderGuideApiArg = {
  orderGuideId: number;
  productId: number;
};
export type ReorderOrderGuideCategoriesApiResponse = /** status 204 No Content */ void;
export type ReorderOrderGuideCategoriesApiArg = {
  orderGuideId: number;
  reorderOrderGuideCategoriesRequest: ReorderOrderGuideCategoriesRequest;
};
export type ReorderOrderGuideItemApiResponse = /** status 204 No Content */ void;
export type ReorderOrderGuideItemApiArg = {
  orderGuideId: number;
  reorderOrderGuideItemRequest: ReorderOrderGuideItemRequest;
};
export type SetProductQuantityInOrderGuideApiResponse = /** status 200 Success */ SetProductQuantityInOrderGuideResponse;
export type SetProductQuantityInOrderGuideApiArg = {
  orderGuideId: number;
  productId: number;
  setProductQuantityInOrderGuideRequest: SetProductQuantityInOrderGuideRequest;
};
export type ConfirmOrderGuideUploadApiResponse = /** status 200 Success */ ConfirmOrderGuideUploadResponse;
export type ConfirmOrderGuideUploadApiArg = {
  orderGuideUploadId: number;
  confirmOrderGuideUploadRequest: ConfirmOrderGuideUploadRequest;
};
export type DeleteOrderGuideUploadApiResponse = unknown;
export type DeleteOrderGuideUploadApiArg = number;
export type GetOrderGuideUploadApiResponse = /** status 200 Success */ GetOrderGuideUploadResponse;
export type GetOrderGuideUploadApiArg = number;
export type ConfirmOrderGuideUploadEntryApiResponse = /** status 200 Success */ ConfirmOrderGuideUploadEntryResponse;
export type ConfirmOrderGuideUploadEntryApiArg = {
  orderGuideUploadId: number;
  confirmOrderGuideUploadEntryRequest: ConfirmOrderGuideUploadEntryRequest;
};
export type CreateProductFromOrderGuideUploadEntryApiResponse = /** status 200 Success */ CreateProductFromOrderGuideUploadEntryResponse;
export type CreateProductFromOrderGuideUploadEntryApiArg = {
  orderGuideUploadId: number;
  entryId: number;
};
export type GetOrderGuideUploadEntryApiResponse = /** status 200 Success */ GetOrderGuideUploadEntryResponse;
export type GetOrderGuideUploadEntryApiArg = {
  orderGuideUploadId: number;
  entryId: number;
};
export type SetProductOrderGuideUploadEntryApiResponse = /** status 200 Success */ SetProductOrderGuideUploadEntryResponse;
export type SetProductOrderGuideUploadEntryApiArg = {
  orderGuideUploadId: number;
  entryId: number;
  setProductOrderGuideUploadEntryRequest: SetProductOrderGuideUploadEntryRequest;
};
export type UnlinkOrderGuideUploadEntryApiResponse = /** status 200 Success */ UnlinkOrderGuideUploadEntryResponse;
export type UnlinkOrderGuideUploadEntryApiArg = {
  orderGuideUploadId: number;
  entryId: number;
};
export type GetOrderGuideUploadColumnTypesApiResponse = /** status 200 Success */ OrderGuideUploadColumnType[];
export type GetOrderGuideUploadColumnTypesApiArg = void;
export type GetOrderGuideUploadSummariesApiResponse = /** status 200 Success */ GetOrderGuideUploadSummariesResponse;
export type GetOrderGuideUploadSummariesApiArg = number;
export type UploadOrderGuideApiResponse = /** status 200 Success */ UploadOrderGuideResponse;
export type UploadOrderGuideApiArg = {
  businessId: number;
  uploadOrderGuideRequest: UploadOrderGuideRequest;
};
export type CompleteOrderApiResponse = /** status 200 Success */ CompleteOrderResponse;
export type CompleteOrderApiArg = number;
export type GetOrderDetailsByIdApiResponse = /** status 200 Success */ GetOrderByIdResponse;
export type GetOrderDetailsByIdApiArg = number;
export type GetOrdersApiResponse = /** status 200 Success */ GetOrdersResponse;
export type GetOrdersApiArg = {
  take?: number | null;
  skip?: number | null;
  status?: OrderStatus[] | null;
  endDate?: string | null;
  startDate?: string | null;
  businessIds?: number[] | null;
};
export type GetRecentOrdersApiResponse = /** status 200 Success */ GetRecentOrdersResponse;
export type GetRecentOrdersApiArg = {
  businessId: number;
  take?: number | null;
};
export type CreatePaymentMethodApiResponse = /** status 200 Success */ CreatePaymentMethodResponse;
export type CreatePaymentMethodApiArg = {
  businessId: number;
  createPaymentMethodRequest: CreatePaymentMethodRequest;
};
export type GetBusinessPaymentMethodsApiResponse = /** status 200 Success */ GetPaymentMethodsResponse;
export type GetBusinessPaymentMethodsApiArg = number;
export type DeletePaymentMethodApiResponse = unknown;
export type DeletePaymentMethodApiArg = {
  businessId: number;
  paymentMethodId: number;
};
export type SubmitPaymentApiResponse = /** status 200 Success */ SubmitPaymentResponse;
export type SubmitPaymentApiArg = {
  businessId: number;
  submitPaymentRequest: SubmitPaymentRequest;
};
export type GetProductMetadataApiResponse = /** status 200 Success */ GetProductResponse;
export type GetProductMetadataApiArg = {
  productId: number;
  includePendingUploadProducts?: boolean | null;
};
export type GetProductPriceHistoryApiResponse = /** status 200 Success */ GetProductPriceHistoryResponse;
export type GetProductPriceHistoryApiArg = number;
export type GetSearchProductFacetsApiResponse = /** status 200 Success */ GetSearchFacetsResponse;
export type GetSearchProductFacetsApiArg = number | null;
export type IrnapiEndpointsPublicContactUsEndpointApiResponse = /** status 204 No Content */ void;
export type IrnapiEndpointsPublicContactUsEndpointApiArg = ContactRequest;
export type GetUserApiResponse = /** status 200 Success */ GetUserResponse;
export type GetUserApiArg = void;
export type GetUserRolesApiResponse = /** status 200 Success */ GetUserRolesResponse;
export type GetUserRolesApiArg = void;
export type ListUsersApiResponse = /** status 200 Success */ ListUsersResponse;
export type ListUsersApiArg = void;
export type OnboardUserApiResponse = /** status 200 Success */ OnboardUserResponse;
export type OnboardUserApiArg = OnboardUserRequest;
export type UpdateUserInfoApiResponse = /** status 200 Success */ UpdateUserInfoResponse;
export type UpdateUserInfoApiArg = UpdateUserInfoRequest;
export type SearchProductsApiResponse = /** status 200 Success */ SearchProductsResponse;
export type SearchProductsApiArg = SearchProductsRequest;
export type DistributorLoginDto = {
  id: number;
  businessId: number;
  distributorId: number;
  username: string;
  createdAt: string;
  password: string;
};
export type DistributorOrderGuideSummary = {
  name: string;
  numberOfItems: number;
  metadata: string;
};
export type CreateBusinessDistributorLoginResponse = {
  distributorLogin: DistributorLoginDto;
  availableOrderGuides: DistributorOrderGuideSummary[];
};
export type ProblemDetailsError = {
  name: string;
  reason: string;
  code?: string | null;
  severity?: string | null;
};
export type ProblemDetails = {
  type: string;
  title: string;
  status: number;
  instance: string;
  traceId: string;
  detail?: string | null;
  errors: ProblemDetailsError[];
};
export type CreateBusinessDistributorLoginRequest = {
  username: string;
  password: string;
  distributorType: string;
};
export type AddressDto = {
  careOf?: string | null;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zipCode: string;
};
export type BusinessDto = {
  id: number;
  name: string;
  address: AddressDto;
  taxId?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  operatingDbaName?: string | null;
  registrationType?: BusinessRegistrationType | null;
  createdAt: string;
};
export type CreateOrUpdateBusinessRequest = {
  name: string;
  taxId?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  operatingName?: string | null;
  address: AddressDto;
  registrationType?: BusinessRegistrationType | null;
};
export type UpdateBusinessDistributorLoginRequest = {
  username: string;
  password: string;
};
export type BusinessStatDatum = {
  key: string;
  value: number;
};
export type BusinessStat = {
  name: string;
  stats: BusinessStatDatum[];
};
export type DistributorDto = {
  id: number;
  name: string;
  imageUrl?: string | null;
  ownedByBusinessId: number;
};
export type CreateDistributorResponse = {
  distributor: DistributorDto;
};
export type CreateDistributorRequest = {
  businessId: number;
  name: string;
  minimumOrderAmount: number;
  minimumOrderQuantity: number;
  servicesZipCodes: string[];
  contactName: string;
  contactEmail: string;
  contactPhone?: string | null;
};
export type GetDistributorsResponse = {
  distributors: DistributorDto[];
};
export type VerifyDistributorOtpResponse = {
  success: boolean;
  message: string;
};
export type VerifyDistributorOtpRequest = {
  requestId: string;
  otp: string;
};
export type AddProductToOrderGuideResponse = {
  orderGuideItemId?: number | null;
};
export type AddProductToOrderGuideRequest = {
  productMetadataId: number;
};
export type ChangeOrderGuideTypeRequest = {
  type: OrderGuideType;
};
export type DistributorProductDto = {
  id: number;
  name: string;
  sku?: string | null;
  description?: string | null;
  thumbnailUrl?: string | null;
  imageUrls?: string[] | null;
  price: number;
  unit: UnitSize;
  size: number;
  unitSize?: string | null;
  updatedAt: string;
  distributor?: DistributorDto | null;
  pricePerPiece?: number | null;
  unitsPerCase?: number | null;
  isCatchWeight: boolean;
};
export type OrderLineItemDto = {
  id: number;
  quantity: number;
  distributorProduct: DistributorProductDto;
};
export type OrderDetailsDto = {
  id: number;
  orderId: string;
  createdAt: string;
  status: OrderStatus;
  total: number;
  lineItems?: OrderLineItemDto[] | null;
  business?: BusinessDto | null;
};
export type CreateOrderFromOrderGuideResponse = {
  orderDetails: OrderDetailsDto;
};
export type ProductMetadataDto = {
  id: number;
  name: string;
  brand: string;
  mpn?: string | null;
  upc?: string | null;
  description?: string | null;
  thumbnailUrl?: string | null;
  imageUrls?: string[] | null;
  categoryId?: number | null;
  categoryName?: string | null;
  unit: UnitSize;
  size: number;
  unitSize?: string | null;
  products?: DistributorProductDto[] | null;
};
export type OrderGuideItemDto = {
  id: number;
  productMetadataId: number;
  quantity: number;
  par?: number | null;
  onHand?: number | null;
  productMetadata?: ProductMetadataDto | null;
  index: number;
  categoryId?: number | null;
};
export type OrderGuideItemCategoryDto = {
  id: number;
  name: string;
};
export type OrderGuideCategoryOrderDto = {
  category: OrderGuideItemCategoryDto;
  index: number;
};
export type OrderGuideDto = {
  id: number;
  businessId: number;
  name: string;
  description?: string | null;
  type: OrderGuideType;
  items: OrderGuideItemDto[];
  categories: OrderGuideCategoryOrderDto[];
};
export type CreateOrderGuideResponse = {
  orderGuide: OrderGuideDto;
};
export type CreateOrderGuideRequest = {
  businessId: number;
  name: string;
  description?: string | null;
};
export type CreateOrderGuideItemCategoryResponse = {
  category: OrderGuideItemCategoryDto;
};
export type CreateOrderGuideItemCategoryRequest = {
  name: string;
  description?: string | null;
};
export type GetOrderGuideByIdResponse = {
  orderGuide?: OrderGuideDto | null;
};
export type UpdateOrderGuideResponse = {
  orderGuide: OrderGuideDto;
};
export type UpdateOrderGuideRequest = {
  name?: string | null;
  description?: string | null;
};
export type OrderGuideDisplayCategoryDto = {
  id: number;
  name: string;
  index: number;
  items: OrderGuideItemDto[];
};
export type OrderGuideDisplayDto = {
  id: number;
  businessId: number;
  name: string;
  description?: string | null;
  type: OrderGuideType;
  categories: OrderGuideDisplayCategoryDto[];
};
export type GetOrderGuideDisplayResponse = {
  orderGuide?: OrderGuideDisplayDto | null;
};
export type OrderGuideSummaryDto = {
  id: number;
  name: string;
  businessName: string;
  businessId: number;
  itemCount: number;
  itemsRequested: number;
  currentEstimatedTotal: number;
};
export type GetOrderGuideSummariesResponse = {
  summaries: OrderGuideSummaryDto[];
};
export type ReorderOrderGuideCategoriesRequest = {
  orderedCategoryIds: number[];
};
export type ReorderOrderGuideItemRequest = {
  categoryId: number;
  itemId: number;
  newIndex: number;
};
export type SetProductQuantityInOrderGuideResponse = {
  item: OrderGuideItemDto;
};
export type SetProductQuantityInOrderGuideRequest = {
  quantity?: number | null;
  par?: number | null;
  onHand?: number | null;
};
export type ConfirmOrderGuideUploadResponse = {
  orderGuideId?: number | null;
};
export type ConfirmOrderGuideUploadRequest = {
  uploadType: UploadType;
  loadItemsIntoOrderGuideId?: number | null;
};
export type JsonDocument = {
  rootElement: any;
};
export type OrderGuideUploadEntryDto = {
  id: number;
  rowData: string;
  potentialProductIds?: number[] | null;
  potentialProductScores?: number[] | null;
  status: UploadProcessingStatus;
  productMetadataId?: number | null;
  parsedData?: string | null;
  productMetadata?: ProductMetadataDto | null;
  errorReason?: string | null;
};
export type OrderGuideUploadDto = {
  id: number;
  businessId: number;
  distributorId: number;
  status: UploadProcessingStatus;
  uploadType: UploadType;
  message?: string | null;
  fileKey: string;
  entryCount: number;
  fileColumns: string[];
  fileType: string;
  fileSize: number;
  skipFirstRow: boolean;
  extractMpnUpcFromDescription: boolean;
  isInternalUpload: boolean;
  columnTypes: JsonDocument;
  entries: OrderGuideUploadEntryDto[];
  createdAt: string;
  updatedAt: string;
};
export type GetOrderGuideUploadResponse = {
  orderGuideUpload: OrderGuideUploadDto;
};
export type ConfirmOrderGuideUploadEntryResponse = {
  entry: OrderGuideUploadEntryDto;
};
export type ConfirmOrderGuideUploadEntryRequest = {
  entryId: number;
};
export type CreateProductFromOrderGuideUploadEntryResponse = {
  product: ProductMetadataDto;
};
export type GetOrderGuideUploadEntryResponse = {
  entry: OrderGuideUploadEntryDto;
};
export type SetProductOrderGuideUploadEntryResponse = {
  entry: OrderGuideUploadEntryDto;
};
export type SetProductOrderGuideUploadEntryRequest = {
  productMetadataId: number;
};
export type UnlinkOrderGuideUploadEntryResponse = {
  entry: OrderGuideUploadEntryDto;
};
export type OrderGuideUploadSummaryDto = {
  id: number;
  businessId: number;
  status: UploadProcessingStatus;
  message?: string | null;
  fileKey: string;
  entryCount: number;
  processedEntryCount: number;
  createdAt: string;
  updatedAt: string;
  eta?: string | null;
  itemsPerSecond?: number | null;
};
export type GetOrderGuideUploadSummariesResponse = {
  summaries: OrderGuideUploadSummaryDto[];
};
export type UploadOrderGuideResponse = {
  orderGuideUploadId: number;
};
export type UploadOrderGuideRequest = {
  fileName: string;
  fileType: string;
  fileSize: number;
  columns: string[];
  records: {
    [key: string]: any;
  }[];
  dataString?: string | null;
  columnTypes: {
    [key: string]: string;
  };
  skipFirstRow: boolean;
  extractMpnUpcFromDescription: boolean;
  distributorId: number;
  uploadType: UploadType;
};
export type CompleteOrderResponse = {
  order?: OrderDetailsDto | null;
};
export type GetOrderByIdResponse = {
  order?: OrderDetailsDto | null;
};
export type OrderSummaryDto = {
  id: number;
  orderId: string;
  createdAt: string;
  status: OrderStatus;
  total: number;
  business: BusinessDto;
};
export type GetOrdersResponse = {
  orders: OrderSummaryDto[];
};
export type GetRecentOrdersResponse = {
  recentOrders: OrderSummaryDto[];
};
export type CreatePaymentMethodResponse = {
  paymentMethodId?: number | null;
};
export type CreatePaymentMethodRequest = {
  paymentMethodType: string;
  paymentMethodToken: string;
};
export type CreditCardMethodDto = {
  cardType: string;
  maskedCardNumber: string;
  expirationDate: string;
};
export type BankAccountMethodDto = {
  accountType: string;
  maskedAccountNumber: string;
};
export type PaymentMethodDto = {
  id: number;
  isCreditCard: boolean;
  creditCard?: CreditCardMethodDto | null;
  bankAccount?: BankAccountMethodDto | null;
};
export type GetPaymentMethodsResponse = {
  paymentMethods: PaymentMethodDto[];
};
export type TransactionResponse = {
  transactionId: string;
  transactionStatus: string;
  authCode: string;
};
export type SubmitPaymentResponse = {
  transaction: TransactionResponse;
};
export type SubmitPaymentRequest = {
  orderId: number;
  paymentMethodId: number;
  useAvailableCredit?: boolean | null;
};
export type GetProductResponse = {
  product: ProductMetadataDto;
};
export type ProductPriceHistoryDto = {
  distributors: string[];
  data: {
    [key: string]: string;
  }[];
};
export type GetProductPriceHistoryResponse = {
  productPriceHistory: ProductPriceHistoryDto;
};
export type SearchFacetDto = {
  facet: SearchFacet;
  value: string;
};
export type GetSearchFacetsResponse = {
  facets: SearchFacetDto[];
};
export type ContactRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  message: string;
};
export type UserInfoDto = {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  businesses?: BusinessDto[] | null;
  roles?: ApplicationRole[] | null;
  hasOnboarded: boolean;
  metadata?: {
    [key: string]: string;
  } | null;
};
export type GetUserResponse = {
  user?: UserInfoDto | null;
};
export type GetUserRolesResponse = {
  roles?: ApplicationRole[] | null;
};
export type ThinUserInfoDto = {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
};
export type ListUsersResponse = {
  users: ThinUserInfoDto[];
};
export type OnboardUserResponse = {
  success: boolean;
};
export type OnboardUserRequest = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: AddressDto;
  businessName: string;
  businessTaxId: string;
  businessEmail: string;
  businessPhoneNumber: string;
  businessOperatingName: string;
  businessAddress: AddressDto;
  businessRegistrationType: BusinessRegistrationType;
};
export type UpdateUserInfoResponse = {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
};
export type UpdateUserInfoRequest = {
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email: string;
};
export type SearchProductResult = {
  product: ProductMetadataDto;
  score: number;
};
export type SearchProductsResponse = {
  totalCount: number;
  products: SearchProductResult[];
  facets?: SearchFacetDto[] | null;
};
export type SearchProductsRequest = {
  businessId: number;
  skip: number;
  take: number;
  search?: string | null;
  facets?: SearchFacetDto[] | null;
  minScore?: number | null;
};
export enum BusinessRegistrationType {
  Unknown = "Unknown",
  SoleProprietorship = "SoleProprietorship",
  Partnership = "Partnership",
  Incorporated = "Incorporated",
  NonProfit = "NonProfit",
  LlcDisregardedEntity = "LLCDisregardedEntity",
  LlccCorporation = "LLCCCorporation",
  LlcsCorporation = "LLCSCorporation",
  LlcPartnership = "LLCPartnership",
  SubSCorporation = "SubSCorporation",
}
export enum OrderGuideType {
  Quantity = "Quantity",
  Par = "Par",
}
export enum OrderStatus {
  NotPlaced = "NotPlaced",
  Pending = "Pending",
  Shipped = "Shipped",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
  Returned = "Returned",
  Refunded = "Refunded",
  Disputed = "Disputed",
}
export enum UnitSize {
  Unknown = "Unknown",
  Each = "Each",
  Case = "Case",
  Pound = "Pound",
}
export enum UploadType {
  NewOrderGuide = "NewOrderGuide",
  PricingOnly = "PricingOnly",
  LoadIntoOrderGuide = "LoadIntoOrderGuide",
}
export enum UploadProcessingStatus {
  Unknown = "Unknown",
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Failed = "Failed",
  Confirmed = "Confirmed",
}
export enum OrderGuideUploadColumnType {
  Unknown = "Unknown",
  Name = "Name",
  Description = "Description",
  Price = "Price",
  Unit = "Unit",
  Size = "Size",
  UnitSize = "Unit Size",
  Brand = "Brand",
  Category = "Category",
  CategoryId = "CategoryId",
  Manufacturer = "Manufacturer #",
  Upc = "UPC",
  DistributorSku = "Distributor SKU",
  Thumbnail = "Thumbnail",
  Images = "Images",
  PricePerUnit = "PricePerUnit",
  UnitsPerCase = "UnitsPerCase",
  IsCatchWeight = "IsCatchWeight",
}
export enum SearchFacet {
  Name = "Name",
  Brand = "Brand",
  Mpn = "MPN",
  Upc = "UPC",
  Sku = "SKU",
  UnitSize = "UnitSize",
  Category = "Category",
  Distributor = "Distributor",
  DistributorId = "DistributorId",
  Id = "Id",
  Description = "Description",
}
export enum ApplicationRole {
  None = "None",
  Moderator = "Moderator",
  Administrator = "Administrator",
  SuperAdministrator = "SuperAdministrator",
  Developer = "Developer",
}
export const {
  useGetHealthzQuery,
  useLazyGetHealthzQuery,
  useCreateBusinessDistributorLoginMutation,
  useCreateBusinessMutation,
  useGetBusinessesQuery,
  useLazyGetBusinessesQuery,
  useDeleteBusinessDistributorLoginMutation,
  useUpdateBusinessDistributorLoginMutation,
  useDeleteBusinessMutation,
  useGetBusinessQuery,
  useLazyGetBusinessQuery,
  useUpdateBusinessMutation,
  useGetBusinessDistributorLoginQuery,
  useLazyGetBusinessDistributorLoginQuery,
  useGetBusinessStatsQuery,
  useLazyGetBusinessStatsQuery,
  useCreateDistributorMutation,
  useGetDistributorsQuery,
  useLazyGetDistributorsQuery,
  useVerifyDistributorOtpMutation,
  useAddProductToOrderGuideMutation,
  useChangeOrderGuideTypeMutation,
  useCreateOrderFromOrderGuideMutation,
  useCreateOrderGuideMutation,
  useCreateOrderGuideItemCategoryMutation,
  useDeleteOrderGuideMutation,
  useGetOrderGuideByIdQuery,
  useLazyGetOrderGuideByIdQuery,
  useUpdateOrderGuideMutation,
  useDeleteOrderGuideItemCategoryMutation,
  useGetOrderGuideDisplayQuery,
  useLazyGetOrderGuideDisplayQuery,
  useGetOrderGuideSummariesQuery,
  useLazyGetOrderGuideSummariesQuery,
  useRemoveProductFromOrderGuideMutation,
  useReorderOrderGuideCategoriesMutation,
  useReorderOrderGuideItemMutation,
  useSetProductQuantityInOrderGuideMutation,
  useConfirmOrderGuideUploadMutation,
  useDeleteOrderGuideUploadMutation,
  useGetOrderGuideUploadQuery,
  useLazyGetOrderGuideUploadQuery,
  useConfirmOrderGuideUploadEntryMutation,
  useCreateProductFromOrderGuideUploadEntryMutation,
  useGetOrderGuideUploadEntryQuery,
  useLazyGetOrderGuideUploadEntryQuery,
  useSetProductOrderGuideUploadEntryMutation,
  useUnlinkOrderGuideUploadEntryMutation,
  useGetOrderGuideUploadColumnTypesQuery,
  useLazyGetOrderGuideUploadColumnTypesQuery,
  useGetOrderGuideUploadSummariesQuery,
  useLazyGetOrderGuideUploadSummariesQuery,
  useUploadOrderGuideMutation,
  useCompleteOrderMutation,
  useGetOrderDetailsByIdQuery,
  useLazyGetOrderDetailsByIdQuery,
  useGetOrdersQuery,
  useLazyGetOrdersQuery,
  useGetRecentOrdersQuery,
  useLazyGetRecentOrdersQuery,
  useCreatePaymentMethodMutation,
  useGetBusinessPaymentMethodsQuery,
  useLazyGetBusinessPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useSubmitPaymentMutation,
  useGetProductMetadataQuery,
  useLazyGetProductMetadataQuery,
  useGetProductPriceHistoryQuery,
  useLazyGetProductPriceHistoryQuery,
  useGetSearchProductFacetsQuery,
  useLazyGetSearchProductFacetsQuery,
  useIrnapiEndpointsPublicContactUsEndpointMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUserRolesQuery,
  useLazyGetUserRolesQuery,
  useListUsersQuery,
  useLazyListUsersQuery,
  useOnboardUserMutation,
  useUpdateUserInfoMutation,
  useSearchProductsMutation,
} = injectedRtkApi;
