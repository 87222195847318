import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useIsInRole } from "@/hooks";
import { ApplicationRole, DistributorDto, OrderStatus, useGetOrderDetailsByIdQuery } from "@/store/IRN.API.ts";
import { toCurrency } from "@/utils.ts";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function OrderReceiptPage() {
  const { orderId: orderIdParam } = useParams();
  const { data: order, isSuccess: getOrderIsSuccess } = useGetOrderDetailsByIdQuery(parseInt(orderIdParam!));
  const [orderItemsByDistributor, setOrderItemsByDistributor] = useState<{
    [key: string]: {
      id: number;
      quantity: number;
      product: {
        name: string;
        price: number;
        distributor: DistributorDto;
      };
    }[];
  }>({});
  const isDeveloperRole = useIsInRole(ApplicationRole.Developer);
  const navigate = useNavigate();
  const [totalDueToIrn, setTotalDueToIrn] = useState<number>(0);
  const [totalDueByDistributor, setTotalDueByDistributor] = useState<Record<string, number>>({});

  useEffect(() => {
    if (!order || !getOrderIsSuccess) {
      return;
    }

    if (order.order?.status === OrderStatus.NotPlaced) {
      navigate("..", { relative: "path" });
    }

    const itemsByDistributor: { [key: string]: { id: number; quantity: number; product: { name: string; price: number; distributor: DistributorDto } }[] } = {};
    order.order?.lineItems?.forEach((item) => {
      if (!itemsByDistributor[item.distributorProduct.distributor!.name]) {
        itemsByDistributor[item.distributorProduct.distributor!.name] = [];
      }
      itemsByDistributor[item.distributorProduct.distributor!.name].push({
        quantity: item.quantity,
        id: item.id,

        product: {
          name: item.distributorProduct.name,
          price: item.distributorProduct.price,
          distributor: item.distributorProduct.distributor!,
        },
      });
    });

    setOrderItemsByDistributor(itemsByDistributor);

    // Calculate the total owed. This should only include products from distributors who are owned by business ID = 1
    const dueToIrn = Object.keys(itemsByDistributor)
      .filter((distributorName) => itemsByDistributor[distributorName][0].product.distributor.ownedByBusinessId === 1)
      .reduce((acc, distributor) => acc + itemsByDistributor[distributor].reduce((acc, item) => acc + item.quantity * item.product.price, 0), 0);

    setTotalDueToIrn(dueToIrn);

    const dueByDistributor: Record<string, number> = {};
    Object.keys(itemsByDistributor).forEach((distributorName) => {
      if (itemsByDistributor[distributorName][0].product.distributor.ownedByBusinessId !== 1) {
        dueByDistributor[distributorName] = itemsByDistributor[distributorName].reduce((acc, item) => acc + item.quantity * item.product.price, 0);
      }
    });

    setTotalDueByDistributor(dueByDistributor);
  }, [getOrderIsSuccess, isDeveloperRole, order]);

  return (
    <div>
      <div className="p-6">
        <header className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            {/*<Package2Icon className="h-8 w-8 text-primary" />*/}
            <h1 className="text-2xl font-bold">IRN Purchase Order</h1>
          </div>
          <div>
            <p className="text-sm text-gray-500">Order #{order?.order?.orderId}</p>
            <p className="text-sm text-gray-500">
              {/*June 15, 2023*/} {order?.order?.createdAt && new Date(order.order?.createdAt).toLocaleDateString()}
            </p>
          </div>
        </header>
        <main className="grid gap-6">
          <Card>
            <CardHeader>
              <CardTitle>Order Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Item</TableHead>
                    <TableHead>Quantity</TableHead>
                    <TableHead>Price</TableHead>
                    <TableHead className="text-right">Total</TableHead>
                    <TableHead className="text-right">Total due on Delivery</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Object.keys(orderItemsByDistributor).map((distributor) => (
                    <Fragment key={distributor}>
                      <TableRow>
                        <TableCell colSpan={4} className="font-semibold">
                          {distributor} - {orderItemsByDistributor[distributor][0].product.distributor.ownedByBusinessId}
                        </TableCell>
                      </TableRow>
                      {orderItemsByDistributor[distributor].map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.product.name}</TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>{toCurrency(item.product.price)}</TableCell>
                          {item.product.distributor.ownedByBusinessId === 1 ? (
                            <TableCell className="text-right">{toCurrency(item.quantity * item.product.price)}</TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )}
                          {item.product.distributor.ownedByBusinessId !== 1 ? (
                            <TableCell className="text-right">{toCurrency(item.quantity * item.product.price)}</TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )}
                        </TableRow>
                      ))}
                    </Fragment>
                  ))}
                </TableBody>
                <TableFooter>
                  {Object.keys(totalDueByDistributor).map((distributor) => (
                    <TableRow key={distributor}>
                      <TableCell className="text-right font-bold" colSpan={4}>
                        Due to {distributor} on delivery
                      </TableCell>
                      <TableCell className="text-right">{toCurrency(totalDueByDistributor[distributor])}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell className="text-right font-bold" colSpan={3}>
                      Paid to IRN
                    </TableCell>
                    <TableCell className="text-right">{toCurrency(totalDueToIrn)}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>

            {Object.keys(totalDueByDistributor).length > 0 && (
              <CardFooter className="flex flex-col w-full">
                <div className="flex items-center justify-between w-full">
                  <h4 className="font-semibold text-lg">Total:</h4>
                  <h4 className="font-semibold text-lg">
                    {Object.keys(orderItemsByDistributor)
                      .reduce(
                        (acc, distributor) => acc + orderItemsByDistributor[distributor].reduce((acc, item) => acc + item.quantity * item.product.price, 0),
                        0,
                      )
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                  </h4>
                </div>
              </CardFooter>
            )}
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Shipping Address</CardTitle>
            </CardHeader>
            <CardContent>
              <div>
                <p className="font-medium">{order?.order?.business?.operatingDbaName}</p>
                <p>
                  {order?.order?.business?.address.address1} {order?.order?.business?.address.address2}
                </p>
                <p>
                  {order?.order?.business?.address.city}, {order?.order?.business?.address.state} {order?.order?.business?.address.zipCode}
                </p>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Payment Method</CardTitle>
            </CardHeader>
            <CardContent>
              <div>
                <p>Credit Card</p>
                <p>**** **** **** 1234</p>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Order Notes</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-5 text-sm">
                <p>
                  Please be aware that IRN does not accept payment for, or send payment to 3rd party distributors on your behalf. You are responsible for paying
                  those distributors directly, as outlined in the order summary above.
                </p>
                <p>
                  Any questions or concerns about your order shipment or fulfillment should be directed to the distributor(s) listed above. IRN is not
                  responsible for the fulfillment of your order(s).
                </p>
                <p>
                  If you have any questions about your order, please contact IRN at <a href="mailto:support@irnworks.com">support@irnworks.com</a>.
                </p>
              </div>
            </CardContent>
          </Card>
        </main>
      </div>
    </div>
  );
}
