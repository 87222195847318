import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { ChevronRight, type LucideIcon } from "lucide-react";
import React from "react";
import { Link, useMatches } from "react-router-dom";

export function NavMain({ children }: { children: React.ReactNode }) {
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Platform</SidebarGroupLabel>
      <SidebarMenu>{children}</SidebarMenu>
    </SidebarGroup>
  );
}

export function NavMenuGroup({ children }: { children: React.ReactNode }) {
  return (
    <Collapsible asChild defaultOpen={true} className={"group/collapsible"}>
      <SidebarMenuItem>{children}</SidebarMenuItem>
    </Collapsible>
  );
}

export function NavMenuGroupItem({ title, icon, onClick, children }: { title: string; onClick?: () => void; icon: LucideIcon; children: React.ReactNode }) {
  const childCount = React.Children.count(children);
  return (
    <>
      {childCount > 0 ? (
        <>
          <CollapsibleTrigger asChild>
            <SidebarMenuButton tooltip={title} onClick={onClick}>
              {icon && React.createElement(icon)}
              <span>{title}</span>
              <ChevronRight className={`ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90`} />
            </SidebarMenuButton>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <SidebarMenuSub>{children}</SidebarMenuSub>
          </CollapsibleContent>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export function NavMenuLink({ title, icon, url, onClick }: { title: string; icon?: LucideIcon; url: string; onClick?: () => void }) {
  const matches = useMatches();
  const mostSpecificMatch = matches[matches.length - 1];
  return (
    <Link to={url} className={url === mostSpecificMatch.pathname ? "bg-brand-500/10 text-brand-600" : "text-black"}>
      <SidebarMenuButton tooltip={title} onClick={onClick}>
        {icon && React.createElement(icon)}
        <span>{title}</span>
      </SidebarMenuButton>
    </Link>
  );
}

export function NavMenuSubLink({ title, url }: { title: string; url: string }) {
  const matches = useMatches();
  const mostSpecificMatch = matches[matches.length - 1];
  return (
    <SidebarMenuSubItem key={title}>
      <SidebarMenuSubButton asChild>
        <Link to={url} className={url === mostSpecificMatch.pathname ? "bg-brand-500/10 text-brand-600" : "text-black"}>
          <span>{title}</span>
        </Link>
      </SidebarMenuSubButton>
    </SidebarMenuSubItem>
  );
}
