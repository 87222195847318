import { BusinessOrderGuides } from "@/components/order-guide-selection-widget/BusinessOrderGuides.tsx";
import { OrderGuideSummaryDto } from "@/store/IRN.API.ts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OrderGuideWidgetState {
  businesses: BusinessOrderGuides[];
  summaries: OrderGuideSummaryDto[];
}

const initialState: OrderGuideWidgetState = {
  businesses: [],
  summaries: [],
};

export const orderGuideWidgetSlice = createSlice({
  name: "orderGuideWidget",
  initialState,
  reducers: {
    setOrderGuideSummaries: (state, action: PayloadAction<OrderGuideSummaryDto[]>) => {
      // console.log("setOrderGuideSummaries", action.payload);
      state.summaries = action.payload;

      const newBusinesses: BusinessOrderGuides[] = [];
      // console.log(action.payload);
      action.payload?.forEach((summary) => {
        const business = newBusinesses.find((b) => b.id === summary.businessId);

        if (business) {
          // console.log("business found");
          business.guides.push({
            id: summary.id!,
            name: summary.name!,
            itemCount: summary.itemCount!,
            estimatePrice: summary.currentEstimatedTotal!,
            orderedCount: summary.itemsRequested!,
          });
        } else {
          // console.log("business not found");
          newBusinesses.push({
            id: summary.businessId!,
            name: summary.businessName!,
            guides: [
              {
                id: summary.id!,
                name: summary.name!,
                itemCount: summary.itemCount!,
                estimatePrice: summary.currentEstimatedTotal!,
                orderedCount: summary.itemsRequested!,
              },
            ],
          });
        }
      });

      // console.log("newBusinesses", newBusinesses);
      state.businesses = newBusinesses;
    },
  },
});

export const { setOrderGuideSummaries } = orderGuideWidgetSlice.actions;

export default orderGuideWidgetSlice.reducer;

export const selectBusinessOrderGuides = (state: { orderGuideWidget: OrderGuideWidgetState }) => state.orderGuideWidget.businesses;

export const selectBusinessOrderGuidesForBusiness = (businessId: number) => (state: { orderGuideWidget: OrderGuideWidgetState }) =>
  state.orderGuideWidget.businesses.find((b) => b.id === businessId);
