import { AppSidebar } from "@/components/AppSidebar.tsx";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar.tsx";
import { Outlet } from "react-router-dom";

export function MainSidebarLayout() {
  return (
    <>
      <SidebarProvider>
        <AppSidebar />
        <main className="w-full">
          <SidebarTrigger />
          <div className="md:p-10 p-1">
            <Outlet />
          </div>
        </main>
      </SidebarProvider>
    </>
  );
}
